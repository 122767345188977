import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from '../../../core/models/app.models';
import { formatPrice } from '../../../core/helpers/helpers';

@Component({
  selector: 'izzo-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent {
  @Input() product!: Product;
  @Output() selectionToggled = new EventEmitter<void>();

  emitSelectionToggled() {
    if (!this.product.disabled) {
      this.selectionToggled.emit();
    }
  }

  formatPrice(price: number) {
    return formatPrice(price);
  }

  getSelection() {
    return this.product.selected ? 'unselect' : 'select';
  }

  getPrice() {
    if (this.product && this.product.price === 0) {
      return null;
    }

    return `CHF ${this.formatPrice(this.product.price)}`;
  }
}
