import { Component, EventEmitter, Input, Output } from '@angular/core';
import { List, ListInvitee } from '../../../core/models/app.models';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'izzo-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
})
export class ListCardComponent {
  @Input() list!: List | null;
  @Input() listInvitees!: ListInvitee[] | null;
  @Output() openListClicked = new EventEmitter<List>();
  @Output() editListClicked = new EventEmitter<List>();
  @Output() deleteListClicked = new EventEmitter<List>();

  constructor(private eventService: EventService) {}

  get countInviteesInList() {
    this.listInvitees = this.list!.listInvitee!;
    if (this.listInvitees != null) {
      return this.listInvitees.length;
    }
    return [];
  }

  emitOpenList() {
    this.openListClicked.emit(this.list!);
  }

  emitEditList() {
    this.editListClicked.emit(this.list!);
  }

  emitDeleteList() {
    this.deleteListClicked.emit(this.list!);
  }
}
