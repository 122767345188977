import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { ListType } from 'src/app/core/models/app.models';

@Component({
  selector: 'izzo-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
})
export class SelectListComponent implements ControlValueAccessor, OnInit {
  @Input() friendsListText!: string;
  @Input() guestListText!: string;
  @Input() customListText!: string;
  @Input() listTypes!: ListType[] | null;
  @Input() formControl!: UntypedFormControl;
  @Output() selectListTypeClicked = new EventEmitter<number>();

  hasValue: boolean | undefined;

  onChange: any = () => {};

  ngOnInit(): void {
    this.checkValue();
  }

  checkValue(): void {
    this.hasValue = this.formControl.value !== null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: string): void {
    if (val !== this.formControl.value) {
      this.formControl.setValue(val);
      this.onChange(val);
    }
  }

  emitSelectListTypeClicked(id: number) {
    this.formControl.setValue(id);
  }
}
