<div class="footer">
  <div class="footer__menu">
    <div class="footer__menu-wrap">
      <div class="footer__nav-logo">
        <a class="footer__nav-logo-svg" href="https://izzo-app.com" target="_blank">
          <img alt="IZZO Logo" src="assets/images/FLORAL_WHITE_PLAIN_IZZO_LOGO.svg"/>
        </a>
      </div>
      <div class="footer__nav">
        <div class="footer__nav-links">
          <a [href]="servicesLink" class="footer__nav-links-link" target="_blank">
            <img class="footer__nav-links-link-icon" src="assets/images/rightChevronWhiteLogo.png"/>
            Services
          </a>
          <a [href]="aboutLink" class="footer__nav-links-link" target="_blank">
            <img class="footer__nav-links-link-icon" src="assets/images/rightChevronWhiteLogo.png"/>
            About
          </a>
          <a [href]="faqLink" class="footer__nav-links-link" target="_blank">
            <img class="footer__nav-links-link-icon" src="assets/images/rightChevronWhiteLogo.png"/>
            FAQ
          </a>
          <a [href]="mediakitLink" class="footer__nav-links-link" target="_blank">
            <img class="footer__nav-links-link-icon" src="assets/images/rightChevronWhiteLogo.png"/>
            Mediakit
          </a>
        </div>
      </div>
      <div class="footer__blog">
        <span class="footer__blog-title">{{ saferPartyTitleLabel }}</span>
        <span class="footer__blog-text">{{ saferPartyTextLabel }}</span>
        <izzo-button (click)="navigateTo(saferPartyLink)" [label]="saferPartyButtonLabel"
                     class="footer__blog-button" variant="light"></izzo-button>
      </div>
      <div class="footer__contact">
        <span class="footer__contact-title">{{ contactsTitleLabel }}</span>
        <span class="footer__contact-text">{{ contactsTextLabel }}</span>
        <izzo-button (click)="navigateTo(contactLink)" [label]="contactsButtonLabel" class="footer__contact-button"
                     variant="light"></izzo-button>
      </div>
    </div>
  </div>
  <div class="footer__socials-meta">
    <div class="footer__socials-meta-wrap">
      <div class="footer__socials">
        <div class="footer__socials-wrap">
          <svg-icon (click)="navigateTo(instagramLink)" class="footer__socials-icon" key="instagram"></svg-icon>
        </div>
        <div class="footer__socials-wrap">
          <svg-icon (click)="navigateTo(facebookLink)" class="footer__socials-icon" key="facebook"></svg-icon>
        </div>
        <div class="footer__socials-wrap">
          <svg-icon (click)="navigateTo(linkedInLink)" class="footer__socials-icon" key="linkedin"></svg-icon>
        </div>
      </div>
      <div class="footer__meta">
        <a [href]="impressumLink" target="_blank">
          IMPRESSUM
        </a>
        <a [href]="agbLink" target="_blank">
          AGB
        </a>
        <a [href]="privacyLink" target="_blank">
          PRIVACY
        </a>
      </div>
    </div>
  </div>
  <div class="footer__izzo-since">
    <span>© SINCE 2022, IZZO</span>
  </div>
</div>
