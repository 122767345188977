import { Pipe, PipeTransform, inject } from '@angular/core';
import { FormControl, FormGroup, NgControl } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'translateError',
  pure: false,
})
export class TranslateErrorPipe implements PipeTransform {
  #translatePipe = inject(TranslatePipe);

  transform(formField: FormControl | FormGroup | NgControl): any {
    if (!formField) {
      return null;
    }

    if (formField.untouched) {
      return null;
    }

    const errors = formField.errors ?? {};
    const firstError = Object.keys(errors)[0];

    if (!firstError || firstError == '') {
      return null;
    }

    const key = `izzo.admin.forms.errors.${firstError}`;

    return this.#translatePipe.transform(key, errors[firstError]);
  }
}
