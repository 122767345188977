import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { provideSvgIcons, SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { AutosizeModule } from 'ngx-autosize';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { NgxPaginationModule } from 'ngx-pagination';
import { SelectListComponent } from '../features/create-list/select-list/select-list.component';
import { AllInviteesComponent } from './components/allInvitees/allInvitees.component';
import { AllListsComponent } from './components/allLists/allLists.component';
import { AllLoungesComponent } from './components/allLounges/allLounges.component';
import { AllReservationsComponent } from './components/allReservations/allReservations.component';
import { ButtonLinkComponent } from './components/button-link/button-link.component';
import { ButtonComponent } from './components/button/button.component';
import { CreateEditListFormComponent } from './components/create-edit-list-form/create-edit-list-form.component';
import { CreateEditLoungeFormComponent } from './components/create-edit-lounge-form/create-edit-lounge-form.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { EventFilterComponent } from './components/event-filter/event-filter.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { FilterBadgeComponent } from './components/filter-badge/filter-badge.component';
import { FooterComponent } from './components/footer/footer.component';
import { GenreDropdownComponent } from './components/genre-dropdown/genre-dropdown.component';
import { HeaderComponent } from './components/header/header.component';
import { MobileMenuComponent } from './components/header/partials/mobile-menu/mobile-menu.component';
import { InputCheckboxComponent } from './components/input-checkbox/input-checkbox.component';
import { InputDateComponent } from './components/input-date/input-date.component';
import { InputImageComponent } from './components/input-image/input-image.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { InputTextareaComponent } from './components/input-textarea/input-textarea.component';
import { InputTimeComponent } from './components/input-time/input-time.component';
import { ListCardComponent } from './components/list-card/list-card.component';
import { ListInviteesComponent } from './components/list-invitees/list-invitees.component';
import { LocationDropdownComponent } from './components/location-dropdown/location-dropdown.component';
import { LoungeCardComponent } from './components/lounge-card/lounge-card.component';
import { LoungeDropdownComponent } from './components/lounge-dropdown/lounge-dropdown.component';
import { LoungeTypeDropdownComponent } from './components/loungeType-dropdown/loungeType-dropdown.component';
import { PartyCardComponent } from './components/party-card/party-card.component';
import { PartyGridComponent } from './components/party-grid/party-grid.component';
import { ProductComponent } from './components/product/product.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ReadOnlyListInviteesComponent } from './components/readOnly-list-invitees/readOnly-list-invitees.component';
import { ReservationCardComponent } from './components/reservation-card/reservation-card.component';
import { ReservationRequestComponent } from './components/reservation-request/reservation-request.component';
import { StepMenuComponent } from './components/step-menu/step-menu.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { UploadEditEventFormComponent } from './components/upload-edit-event-form/upload-edit-event-form.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { FirstErrorPipe } from './pipes/first-error.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TouchedErrorsPipe } from './pipes/touched-errors.pipe';
import { TranslateErrorPipe } from './pipes/translate-error.pipe';
import { OrganizerDropdownComponent } from './components/organizer-dropdown/organizer-dropdown.component';
import { LocationTypeaheadComponent } from './components/location-typeahead/location-typeahead.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { TranslateIfExistsPipe } from './pipes/translate-if-exists.pipe';
import { addIcon } from '../svg/add';
import { editIcon } from '../svg/edit';
import { deleteIcon } from '../svg/delete';
import { instagramIcon } from '../svg/instagram';
import { facebookIcon } from '../svg/facebook';
import { linkedinIcon } from '../svg/linkedin';
import { twitterIcon } from '../svg/twitter';
import { infoIcon } from '../svg/info';
import { closeIcon } from '../svg/close';
import { userIcon } from '../svg/user';
import { dashboardIcon } from '../svg/dashboard';
import { resetIcon } from '../svg/reset';
import { topIcon } from '../svg/top';
import { bottomIcon } from '../svg/bottom';
import { checkIcon } from '../svg/check';
import { eyeIcon } from '../svg/eye';
import { checkCircleIcon } from '../svg/checkCircle';
import { warningIcon } from '../svg/warning';
import { groupIcon } from '../svg/group';
import { chevronLeftIcon } from '../svg/chevron-left';
import { chevronRightIcon } from '../svg/chevron-right';
import { fourCirclesIcon } from '../svg/four-circles';
import { loungeIcon } from '../svg/lounge';
import { exclamationIcon } from '../svg/exclamation';
import { listIcon } from '../svg/list';
import { packageIcon } from '../svg/package';
import { seatIcon } from '../svg/seat';
import { emailIcon } from '../svg/email';
import { telIcon } from '../svg/tel';
import { eyeClosedIcon } from '../svg/eyeClosed';
import { eyeOpenedIcon } from '../svg/eyeOpened';
import { ZipTypeaheadComponent } from './components/zip-typeahead/zip-typeahead.component';
import { TabStatusPipe } from './pipes/tab-status.pipe';
import { RegionDropdownComponent } from './components/region-dropdown/region-dropdown.component';
import { EventboxEmptyComponent } from './components/eventbox-empty/eventbox-empty.component';

const declarations = [
  ButtonComponent,
  ButtonLinkComponent,
  FooterComponent,
  HeaderComponent,
  PartyGridComponent,
  PartyCardComponent,
  InputTextComponent,
  FirstErrorPipe,
  TouchedErrorsPipe,
  TranslateErrorPipe,
  TranslateIfExistsPipe,
  EventListComponent,
  InputDateComponent,
  ProgressBarComponent,
  UploadEditEventFormComponent,
  LocationDropdownComponent,
  InputTimeComponent,
  GenreDropdownComponent,
  InputTextareaComponent,
  ProductComponent,
  InputCheckboxComponent,
  DatepickerComponent,
  SafeHtmlPipe,
  StepperComponent,
  EventFilterComponent,
  FilterBadgeComponent,
  AutofocusDirective,
  DialogComponent,
  CreateEditListFormComponent,
  ListInviteesComponent,
  SelectListComponent,
  ListCardComponent,
  ReadOnlyListInviteesComponent,
  ToggleSwitchComponent,
  AllListsComponent,
  AllInviteesComponent,
  LoungeCardComponent,
  ReservationCardComponent,
  CreateEditLoungeFormComponent,
  LoungeTypeDropdownComponent,
  ReservationRequestComponent,
  LoungeDropdownComponent,
  AllReservationsComponent,
  AllLoungesComponent,
  ZipTypeaheadComponent,
  TabStatusPipe,
  RegionDropdownComponent,
  MobileMenuComponent,
  StepMenuComponent,
  InputImageComponent,
  OrganizerDropdownComponent,
  LocationTypeaheadComponent,
  InputImageComponent,
  EventboxEmptyComponent,
];

@NgModule({
  declarations: [declarations],
  exports: [declarations],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ImageCropperComponent,
    AutosizeModule,
    TranslateModule,
    ClipboardModule,
    RouterLink,
    NgxPaginationModule,
    NgOptimizedImage,
    ClickOutsideModule,
    SvgIconComponent,
  ],
  providers: [
    TranslatePipe,
    provideSvgIcons([
      addIcon,
      editIcon,
      deleteIcon,
      instagramIcon,
      facebookIcon,
      linkedinIcon,
      twitterIcon,
      infoIcon,
      closeIcon,
      userIcon,
      dashboardIcon,
      editIcon,
      resetIcon,
      topIcon,
      bottomIcon,
      checkIcon,
      eyeIcon,
      checkCircleIcon,
      warningIcon,
      groupIcon,
      chevronLeftIcon,
      chevronRightIcon,
      fourCirclesIcon,
      loungeIcon,
      exclamationIcon,
      listIcon,
      packageIcon,
      seatIcon,
      emailIcon,
      telIcon,
      eyeClosedIcon,
      eyeOpenedIcon,
    ]),
  ],
})
export class SharedModule {}
