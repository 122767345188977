@if (list!.id === 0) {
  <div class="readOnly-list-invitees-empty">
    <h1>{{ 'izzo.admin.listReadOnly.notFoundTitle' | translate }}</h1>
    <p>{{ 'izzo.admin.listReadOnly.notFoundText' | translate }}</p>
  </div>
}
@if (list!.id !== 0) {
  <div class="readOnly-list-invitees">
    <div class="infos-main-inviteesRatio">
      <svg-icon color="#FFE248" key="group"></svg-icon>
      <div class="infos-main-inviteesRatio-text">
        {{ countInviteesInList }}/{{ list?.maxInvitees }}
      </div>
    </div>
    <div class="infos">
      <div class="infos-main">
        <h2 class="infos-main-title">{{ list?.name }}</h2>
        <p class="infos-main-email">{{ list?.emailPromoter }}</p>
        <p class="infos-main-price">
          {{
            !list?.price
              ? ('izzo.admin.list.priceText' | translate) +
                ': ' +
                ('izzo.admin.shared.free' | translate)
              : ('izzo.admin.list.priceText' | translate) +
                ': ' +
                list?.price +
                ' CHF'
          }}
        </p>
      </div>
      <div class="infos-link">
        <div class="infos-link-copy">
          <izzo-input-text
            [formControl]="listLinkFormGroup.controls.link"
            [errorMessage]="
              listLinkFormGroup.controls.link
                | touchedErrors
                | firstError
                | translate
            "
            [placeholder]="'izzo.admin.list.link' | translate"
            [readonly]="true"
            class="infos-link-copy-input"
            variant="dark"
          ></izzo-input-text>
          <izzo-button
            (buttonClicked)="emitCopyLink()"
            [isFullwidth]="true"
            class="infos-link-copy-button"
            >{{ 'izzo.admin.list.copyText' | translate }}
          </izzo-button>
        </div>
        @if (copyTextVisible) {
          <p class="infos-link-copy-button-text">
            {{ 'izzo.admin.list.copyTextToClipboard' | translate }}
          </p>
        }
      </div>
    </div>
    <div class="csv-wrapper">
      <div class="csv">
        <div class="csv-template">
          <a (click)="emitGetCsvTemplateClicked()">
            {{ 'izzo.admin.list.templateCSV' | translate }}
          </a>
        </div>
        <div class="csv-importExport">
          <izzo-button
            (buttonClicked)="import.click()"
            [isFullwidth]="true"
            class="csv-importButton"
            variant="light-dark"
            >{{ 'izzo.admin.list.importCSV' | translate }}
          </izzo-button>
          <izzo-button
            (buttonClicked)="emitExportCsvClicked()"
            [isFullwidth]="true"
            class="csv-exportButton"
            variant="light-dark"
            >{{ 'izzo.admin.list.exportCSV' | translate }}
          </izzo-button>
        </div>
      </div>
    </div>
    <div class="addInviteeForms-wrapper">
      <div
        (keydown.enter)="emitAddInviteeToListClicked()"
        class="addInviteeForms"
      >
        <izzo-input-text
          [formControl]="addInviteeToListFormGroup.controls.firstName"
          [errorMessage]="
            addInviteeToListFormGroup.controls.firstName
              | touchedErrors
              | firstError
              | translate
          "
          [placeholder]="'izzo.admin.list.firstName' | translate"
          variant="dark"
        ></izzo-input-text>
        <izzo-input-text
          [formControl]="addInviteeToListFormGroup.controls.name"
          [errorMessage]="
            addInviteeToListFormGroup.controls.name
              | touchedErrors
              | firstError
              | translate
          "
          [placeholder]="'izzo.admin.list.name' | translate"
          variant="dark"
        ></izzo-input-text>
        <izzo-input-text
          [formControl]="addInviteeToListFormGroup.controls.comment"
          [errorMessage]="
            addInviteeToListFormGroup.controls.comment
              | touchedErrors
              | firstError
              | translate
          "
          [placeholder]="'izzo.admin.list.comment' | translate"
          variant="dark"
        ></izzo-input-text>
        <div class="add-button">
          <izzo-button
            (click)="emitAddInviteeToListClicked()"
            [isFullwidth]="true"
            [label]="'izzo.admin.list.addInvitee' | translate"
            variant="gradient"
          ></izzo-button>
        </div>
      </div>
    </div>
    <div class="csv-mobile-wrapper">
      <div class="csv-mobile">
        <div class="csv-mobile-template">
          <a (click)="emitGetCsvTemplateClicked()">
            {{ 'izzo.admin.list.templateCSV' | translate }}
          </a>
        </div>
        <izzo-button
          (buttonClicked)="import.click()"
          [isFullwidth]="true"
          class="csv-mobile-importButton"
          variant="light-dark"
          >{{ 'izzo.admin.list.importCSV' | translate }}
        </izzo-button>
        <izzo-button
          (buttonClicked)="emitExportCsvClicked()"
          [isFullwidth]="true"
          class="csv-mobile-exportButton"
          variant="light-dark"
          >{{ 'izzo.admin.list.exportCSV' | translate }}
        </izzo-button>
      </div>
    </div>
    <div class="table-wrap">
      <table>
        <thead>
          <tr class="table-header">
            <th class="clickable-th">
              {{ 'izzo.admin.list.firstName' | translate }}
            </th>
            <th class="clickable-th">
              {{ 'izzo.admin.list.name' | translate }}
            </th>
            <th class="clickable-th">
              {{ 'izzo.admin.list.comment' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          @for (
            listInvitee of allInviteesInList
              | paginate: { itemsPerPage: 15, currentPage: page };
            track listInvitee
          ) {
            <tr>
              @if (listInvitee.isReadOnly === true) {
                <td class="hidden-small">{{ listInvitee.firstName }}</td>
                <td class="hidden-small">{{ listInvitee.name }}</td>
                <td class="hidden-small">{{ listInvitee.comment }}</td>
              }
            </tr>
          }
        </tbody>
      </table>
      <div class="pagination">
        <pagination-controls
          [autoHide]="true"
          [responsive]="true"
          class="pagination-control"
          nextLabel="{{ 'izzo.admin.pagination.nextLabel' | translate }}"
          previousLabel="{{
            'izzo.admin.pagination.previousLabel' | translate
          }}"
        ></pagination-controls>
      </div>
    </div>
  </div>
}

<input
  #import
  (change)="importFileChange()"
  accept="text/csv"
  style="display: none"
  type="file"
/>
