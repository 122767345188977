<div class="multi-step-form">
  <izzo-step-menu
    (setStep)="emitSetStep($event)"
    [steps]="steps!"
  ></izzo-step-menu>
  <div class="form-wrapper">
    @if (currentStep === 'eventInformation') {
      <div (keydown.enter)="emitNextFromStepOneClicked()" class="form-group">
        <div class="form-group-wrap">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.generalInformation' | translate }}
          </h2>
          <izzo-input-text
            [errorMessage]="
              eventInformationFormGroup.controls.name
                | touchedErrors
                | firstError
                | translate
            "
            [formControl]="eventInformationFormGroup.controls.name"
            [label]="'izzo.admin.uploadEvent.eventName' | translate"
            [placeholder]="'izzo.admin.uploadEvent.eventName' | translate"
            id="eventName"
            type="text"
            variant="dark"
          >
          </izzo-input-text>
          <div class="field-wrap">
            <izzo-input-date
              [errorMessage]="
                eventInformationFormGroup.controls.startDate
                  | touchedErrors
                  | firstError
                  | translate
              "
              [formControl]="eventInformationFormGroup.controls.startDate"
              [label]="'izzo.admin.uploadEvent.startDate' | translate"
              class="input"
              id="date"
              variant="dark"
            >
            </izzo-input-date>
            <izzo-input-time
              [errorMessage]="
                eventInformationFormGroup.controls.startTime
                  | touchedErrors
                  | firstError
                  | translate
              "
              [formControl]="eventInformationFormGroup.controls.startTime"
              [label]="'izzo.admin.uploadEvent.startTime' | translate"
              class="input"
              id="time"
              variant="dark"
            >
            </izzo-input-time>
          </div>
          <div class="field-wrap">
            <izzo-input-date
              [errorMessage]="
                eventInformationFormGroup.controls.endDate
                  | touchedErrors
                  | firstError
                  | translate
              "
              [formControl]="eventInformationFormGroup.controls.endDate"
              [label]="'izzo.admin.uploadEvent.endDate' | translate"
              class="input"
              id="enddate"
              variant="dark"
            >
            </izzo-input-date>
            <izzo-input-time
              [errorMessage]="
                eventInformationFormGroup.controls.endTime
                  | touchedErrors
                  | firstError
                  | translate
              "
              [formControl]="eventInformationFormGroup.controls.endTime"
              [label]="'izzo.admin.uploadEvent.endTime' | translate"
              class="input"
              id="endtime"
              variant="dark"
            >
            </izzo-input-time>
          </div>
          <p class="error ng-invalid">
            {{
              eventInformationFormGroup | touchedErrors | firstError | translate
            }}
          </p>
        </div>
        <div class="form-group-wrap">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.organizer' | translate }}
          </h2>
          @if (internalLabels && internalLabels.length > 0) {
            <div class="form-group-wrap">
              <izzo-organizer-dropdown
                [errorMessage]="
                  eventInformationFormGroup.controls.internalLabel
                    | touchedErrors
                    | firstError
                    | translate
                "
                [formControl]="eventInformationFormGroup.controls.internalLabel"
                [internalLabels]="internalLabels"
                [label]="'izzo.admin.uploadEvent.organizer' | translate"
                class="input"
                variant="dark"
              >
              </izzo-organizer-dropdown>
            </div>
          } @else {
            <p>{{ 'izzo.admin.uploadEvent.noLabel.text' | translate }}</p>
          }
        </div>
        <div class="form-group-wrap">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.location' | translate }}
          </h2>
          <izzo-location-typeahead
            [errorMessage]="
              eventInformationFormGroup.controls.location
                | touchedErrors
                | firstError
                | translate
            "
            [formControl]="eventInformationFormGroup.controls.location"
            [locations]="locations"
            [newLocationText]="
              'izzo.admin.uploadEvent.newLocationText' | translate
            "
          >
          </izzo-location-typeahead>
          @if (eventInformationFormGroup.controls.location.value === -1) {
            <div class="location-group">
              <izzo-input-text
                [errorMessage]="
                  eventInformationFormGroup.controls.locationName
                    | touchedErrors
                    | firstError
                    | translate
                "
                [formControl]="eventInformationFormGroup.controls.locationName"
                [label]="'izzo.admin.uploadEvent.locationName' | translate"
                [placeholder]="
                  'izzo.admin.uploadEvent.locationName' | translate
                "
                id="locationName"
                type="text"
                variant="dark"
              >
              </izzo-input-text>
              <izzo-input-text
                [errorMessage]="
                  eventInformationFormGroup.controls.locationStreet
                    | touchedErrors
                    | firstError
                    | translate
                "
                [formControl]="
                  eventInformationFormGroup.controls.locationStreet
                "
                [label]="'izzo.admin.uploadEvent.locationStreet' | translate"
                [placeholder]="
                  'izzo.admin.uploadEvent.locationStreet' | translate
                "
                id="locationStreet"
                type="text"
                variant="dark"
              >
              </izzo-input-text>
              <div class="field-wrap">
                <izzo-input-text
                  [errorMessage]="
                    eventInformationFormGroup.controls.locationPlz
                      | touchedErrors
                      | firstError
                      | translate
                  "
                  [formControl]="eventInformationFormGroup.controls.locationPlz"
                  [label]="'izzo.admin.uploadEvent.locationPlz' | translate"
                  [placeholder]="
                    'izzo.admin.uploadEvent.locationPlz' | translate
                  "
                  class="input"
                  id="locationPlz"
                  type="text"
                  variant="dark"
                >
                </izzo-input-text>
                <izzo-input-text
                  [errorMessage]="
                    eventInformationFormGroup.controls.locationProvince
                      | touchedErrors
                      | firstError
                      | translate
                  "
                  [formControl]="
                    eventInformationFormGroup.controls.locationProvince
                  "
                  [label]="
                    'izzo.admin.uploadEvent.locationProvince' | translate
                  "
                  [placeholder]="
                    'izzo.admin.uploadEvent.locationProvince' | translate
                  "
                  class="input"
                  id="locationProvince"
                  type="text"
                  variant="dark"
                >
                </izzo-input-text>
              </div>
            </div>
          }
        </div>
        <div class="form-group-wrap">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.ageRestriction' | translate }}
          </h2>
          <div class="field-wrap">
            <izzo-input-text
              [errorMessage]="
                eventInformationFormGroup.controls.ageRestrictionWomen
                  | touchedErrors
                  | firstError
                  | translate
              "
              [formControl]="
                eventInformationFormGroup.controls.ageRestrictionWomen
              "
              [label]="'izzo.admin.uploadEvent.ageRestrictionWomen' | translate"
              [placeholder]="'izzo.admin.uploadEvent.women' | translate"
              class="input"
              id="ageWomen"
              type="number"
              variant="dark"
            >
            </izzo-input-text>
            <izzo-input-text
              [errorMessage]="
                eventInformationFormGroup.controls.ageRestrictionMen
                  | touchedErrors
                  | firstError
                  | translate
              "
              [formControl]="
                eventInformationFormGroup.controls.ageRestrictionMen
              "
              [label]="'izzo.admin.uploadEvent.ageRestrictionMen' | translate"
              [placeholder]="'izzo.admin.uploadEvent.men' | translate"
              class="input"
              id="ageMen"
              type="number"
              variant="dark"
            >
            </izzo-input-text>
          </div>
        </div>
        <div class="form-group-wrap">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.pressTextLabel' | translate }}
          </h2>
          <izzo-input-textarea
            [errorMessage]="
              eventInformationFormGroup.controls.pressText
                | touchedErrors
                | firstError
                | translate
            "
            [formControl]="eventInformationFormGroup.controls.pressText"
            [label]="'izzo.admin.uploadEvent.pressTextLabel' | translate"
            [placeholder]="
              'izzo.admin.uploadEvent.pressTextPlaceholder' | translate
            "
            id="pressText"
            type="text"
            variant="dark"
          >
          </izzo-input-textarea>
        </div>
        <div class="form-group-buttons single">
          <izzo-button
            (click)="emitNextFromStepOneClicked()"
            [label]="'izzo.admin.uploadEvent.next' | translate"
          ></izzo-button>
        </div>
      </div>
    }
    @if (currentStep === 'genresTags') {
      <div (keydown.enter)="emitNextFromStepTwoClicked()" class="form-group">
        <div class="form-group-wrap">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.genre' | translate }}
            {{ selectedGenres?.length || 0 }} /3
          </h2>
          <izzo-input-text
            (keyup)="searchGenres()"
            [formControl]="searchGenresControl"
            [placeholder]="'izzo.admin.uploadEvent.searchForGenres' | translate"
            id="searchGenre"
            variant="dark"
          >
          </izzo-input-text>
          <div class="filter-badges">
            @for (genre of displayedGenres; track genre) {
              <izzo-filter-badge
                (toggleFilterClicked)="emitToggleGenre(genre.id)"
                [isChecked]="isGenreChecked(genre)"
              >
                {{ genre.genre }}
              </izzo-filter-badge>
            }
          </div>
          @if (displayedGenres.length >= 16) {
            <p (click)="toggleShowAllGenres()" class="load-cta">
              {{
                'izzo.admin.uploadEvent.' +
                  (!showAllGenres ? 'showMoreGenres' : 'showLessGenres')
                  | translate
              }}
            </p>
          }
          @if (
            !eventGenreTagsFormGroup.controls.genres.valid &&
            eventGenreTagsFormGroup.controls.genres.touched
          ) {
            <p class="error ng-invalid">
              {{ 'izzo.admin.uploadEvent.genres.error.text' | translate }}
            </p>
          }
        </div>
        <div class="form-group-wrap">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.tags' | translate }}
            {{ selectedTags?.length || 0 }} /3
          </h2>
          <izzo-input-text
            (keyup)="searchTags()"
            [formControl]="searchTagsControl"
            [placeholder]="'izzo.admin.uploadEvent.searchForTags' | translate"
            id="searchTags"
            variant="dark"
          >
          </izzo-input-text>
          <div class="filter-badges">
            @for (tag of displayedTags; track tag) {
              <izzo-filter-badge
                (toggleFilterClicked)="emitToggleTag(tag.id)"
                [isChecked]="isTagChecked(tag)"
              >
                {{ tag.name }}
              </izzo-filter-badge>
            }
          </div>
          @if (displayedTags.length >= 13) {
            <p (click)="toggleShowAllTags()" class="load-cta">
              {{
                'izzo.admin.uploadEvent.' +
                  (!showAllTags ? 'showMoreTags' : 'showLessTags') | translate
              }}
            </p>
          }
        </div>
        <div class="form-group-buttons">
          <izzo-button
            (click)="emitLastStepClicked()"
            [label]="'izzo.admin.uploadEvent.back' | translate"
            variant="secondary"
          ></izzo-button>
          <izzo-button
            (click)="emitNextFromStepTwoClicked()"
            [label]="'izzo.admin.uploadEvent.next' | translate"
          ></izzo-button>
        </div>
      </div>
    }
    @if (currentStep === 'ticketing') {
      <div (keydown.enter)="emitNextFromStepThreeClicked()" class="form-group">
        <div class="form-group">
          <div class="form-group-wrap">
            <h2 class="form-title">
              {{ 'izzo.admin.uploadEvent.ticketing' | translate }}
            </h2>
            <izzo-input-text
              [errorMessage]="
                eventTicketingFormGroup.controls.fromPrice
                  | touchedErrors
                  | firstError
                  | translate
              "
              [formControl]="eventTicketingFormGroup.controls.fromPrice"
              [label]="'izzo.admin.uploadEvent.ticketsLabel' | translate"
              [placeholder]="'izzo.admin.uploadEvent.ticketsLabel' | translate"
              id="fromPrice"
              type="number"
              variant="dark"
            >
            </izzo-input-text>
            <div class="form-group-ticket">
              <div
                (click)="toggleIsEventFree()"
                [ngClass]="{
                  selected: isFreeEvent,
                  disabled: !isFreeEvent,
                }"
                class="option-wrapper"
                inner-checkbox="true"
                role="checkbox"
                tabindex="0"
              >
                @if (isFreeEvent) {
                  <svg-icon class="option" key="check"></svg-icon>
                }
              </div>
              <p>{{ 'izzo.admin.uploadEvent.isFreeEvent' | translate }}</p>
            </div>
          </div>
          <div class="form-group-wrap">
            <izzo-input-text
              [errorMessage]="
                eventTicketingFormGroup.controls.ticketLink
                  | touchedErrors
                  | firstError
                  | translate
              "
              [formControl]="eventTicketingFormGroup.controls.ticketLink"
              [label]="'izzo.admin.uploadEvent.linkToTicket' | translate"
              [placeholder]="
                'izzo.admin.uploadEvent.linkPlaceholder' | translate
              "
              id="ticketLink"
              type="text"
              variant="dark"
            >
            </izzo-input-text>
            <div class="form-group-ticket">
              <div
                (click)="toggleHasTicketLink()"
                [ngClass]="{
                  selected: hasTicketLink,
                  disabled: !hasTicketLink,
                }"
                class="option-wrapper"
                inner-checkbox="true"
                role="checkbox"
                tabindex="0"
              >
                @if (hasTicketLink) {
                  <svg-icon class="option" key="check"></svg-icon>
                }
              </div>
              <p>{{ 'izzo.admin.uploadEvent.hasNoLinkLabel' | translate }}</p>
            </div>
          </div>
          <div class="form-group-buttons">
            <izzo-button
              (click)="emitLastStepClicked()"
              [label]="'izzo.admin.uploadEvent.back' | translate"
              variant="secondary"
            ></izzo-button>
            <izzo-button
              (click)="emitNextFromStepThreeClicked()"
              [label]="'izzo.admin.uploadEvent.next' | translate"
            ></izzo-button>
          </div>
        </div>
      </div>
    }
    @if (currentStep === 'booster') {
      <div (keydown.enter)="emitNextFromStepFourClicked()">
        <h2 class="form-title">
          {{ 'izzo.admin.uploadEvent.boosters' | translate }}
        </h2>
        <div class="price-summary">
          <p>Total</p>
          @if (price) {
            <p>
              CHF <span class="price">{{ getFormattedPrice(price) }}</span>
            </p>
          }
          @if (!price) {
            <p>CHF <span class="price">0.00</span></p>
          }
        </div>
        @if (products) {
          <div class="products">
            @for (product of products; track product) {
              <izzo-product
                (selectionToggled)="emitSelectionToggled(product)"
                [product]="product"
              ></izzo-product>
            }
            @if (isFlyerSelected) {
              <div class="upload-flyer">
                <h2 class="form-title">
                  {{ 'izzo.admin.uploadEvent.product.uploadFlyer' | translate }}
                </h2>
                <p class="small-description">
                  {{
                    'izzo.admin.uploadEvent.product.uploadFlyerDescription'
                      | translate
                  }}
                </p>
                <izzo-input-image
                  (imageCroppedSuccessfully)="
                    emitImageCroppedSuccessfully($event)
                  "
                  (imageUploadedSuccessfully)="
                    emitImageUploadedSuccessfully($event)
                  "
                  [dialogDescription]="
                    'izzo.admin.shared.flyer.dialogDescription' | translate
                  "
                  [dialogTitle]="
                    'izzo.admin.shared.flyer.dialogTitle' | translate
                  "
                  [image]="eventInformation?.flyer"
                  [originalImage]="originalImage"
                ></izzo-input-image>
                @if (
                  !eventBoostersFormGroup.valid &&
                  eventBoostersFormGroup.controls.flyerUploaded.touched
                ) {
                  <p class="error ng-invalid">
                    {{ 'izzo.admin.uploadEvent.flyer.error.text' | translate }}
                  </p>
                }
              </div>
            }
          </div>
        }
        <div class="form-group-buttons">
          <izzo-button
            (click)="emitLastStepClicked()"
            [label]="'izzo.admin.uploadEvent.back' | translate"
            variant="secondary"
          ></izzo-button>
          <izzo-button
            (click)="emitNextFromStepFourClicked()"
            [label]="'izzo.admin.uploadEvent.next' | translate"
          ></izzo-button>
        </div>
      </div>
    }
    @if (currentStep === 'overview') {
      <div class="form-group">
        <div class="data-wrapper">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.generalInformation' | translate }}
          </h2>
          <div class="data-wrap">
            <div class="data-group">
              <div class="data">
                <p class="data-title">
                  {{ 'izzo.admin.uploadEvent.eventName' | translate }}
                </p>
                <span class="data-value">{{
                  eventInformation?.name ||
                    'izzo.admin.uploadEvent.notSpecified' | translate
                }}</span>
              </div>
              <div class="data">
                <p class="data-title">
                  {{ 'izzo.admin.uploadEvent.organizer' | translate }}
                </p>
                <span class="data-value">{{
                  getLabelById()?.labelName ||
                    'izzo.admin.uploadEvent.notSpecified' | translate
                }}</span>
              </div>
            </div>
            <div class="data-group">
              <div class="data">
                <p class="data-title">
                  {{ 'izzo.admin.uploadEvent.startDate' | translate }}
                </p>
                <span class="data-value">{{
                  eventInformation?.startDate ||
                    'izzo.admin.uploadEvent.notSpecified' | translate
                }}</span>
              </div>
              <div class="data">
                <p class="data-title">
                  {{ 'izzo.admin.uploadEvent.startTime' | translate }}
                </p>
                <span class="data-value">{{
                  eventInformation?.startTime ||
                    'izzo.admin.uploadEvent.notSpecified' | translate
                }}</span>
              </div>
            </div>
            <div class="data-group">
              <div class="data">
                <p class="data-title">
                  {{ 'izzo.admin.uploadEvent.endDate' | translate }}
                </p>
                <span class="data-value">{{
                  eventInformation?.endDate ||
                    'izzo.admin.uploadEvent.notSpecified' | translate
                }}</span>
              </div>
              <div class="data">
                <p class="data-title">
                  {{ 'izzo.admin.uploadEvent.endTime' | translate }}
                </p>
                <span class="data-value">{{
                  eventInformation?.endTime ||
                    'izzo.admin.uploadEvent.notSpecified' | translate
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="data-wrapper">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.location' | translate }}
          </h2>
          <div class="data-wrap">
            <div class="data">
              <span class="data-value">{{
                getLocation(eventInformation?.location || 0) ||
                  'izzo.admin.uploadEvent.notSpecified' | translate
              }}</span>
            </div>
          </div>
        </div>
        <div class="data-wrapper">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.ageRestriction' | translate }}
          </h2>
          <div class="data-wrap">
            <div class="data-group">
              <div class="data">
                <p class="data-title">
                  {{ 'izzo.admin.uploadEvent.ageRestrictionWomen' | translate }}
                </p>
                <span class="data-value">{{
                  eventInformation?.ageRestrictionWomen ||
                    'izzo.admin.uploadEvent.notSpecified' | translate
                }}</span>
              </div>
              <div class="data">
                <p class="data-title">
                  {{ 'izzo.admin.uploadEvent.ageRestrictionMen' | translate }}
                </p>
                <span class="data-value">{{
                  eventInformation?.ageRestrictionMen ||
                    'izzo.admin.uploadEvent.notSpecified' | translate
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="data-wrapper">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.pressTextLabel' | translate }}
          </h2>
          <div class="data-wrap">
            <div class="data">
              <span class="data-value">{{
                eventInformation?.pressText ||
                  'izzo.admin.uploadEvent.notSpecified' | translate
              }}</span>
            </div>
          </div>
        </div>
        <div class="data-wrapper">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.genresTags' | translate }}
          </h2>
          <div class="data-wrap">
            <div class="data">
              <p class="data-title">
                {{ 'izzo.admin.uploadEvent.genre' | translate }}
              </p>
              @if (selectedGenres && selectedGenres.length > 0) {
                <div class="filter-badges">
                  @for (
                    selectedGenre of getSelectedGenresFull();
                    track selectedGenre
                  ) {
                    <izzo-filter-badge [isChecked]="true"
                      >{{ selectedGenre.genre }}
                    </izzo-filter-badge>
                  }
                </div>
              } @else {
                <span class="data-value">{{
                  'izzo.admin.uploadEvent.notSpecified' | translate
                }}</span>
              }
            </div>
            <div class="data">
              <p class="data-title">
                {{ 'izzo.admin.uploadEvent.tags' | translate }}
              </p>
              @if (selectedTags && selectedTags.length > 0) {
                <div class="filter-badges">
                  @for (
                    selectedTag of getSelectedTagsFull();
                    track selectedTag
                  ) {
                    <izzo-filter-badge [isChecked]="true"
                      >{{ selectedTag.name }}
                    </izzo-filter-badge>
                  }
                </div>
              } @else {
                <span class="data-value">{{
                  'izzo.admin.uploadEvent.notSpecified' | translate
                }}</span>
              }
            </div>
          </div>
        </div>
        <div class="data-wrapper">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.ticketing' | translate }}
          </h2>
          <div class="data-wrap">
            <div class="data">
              <p class="data-title">
                {{ 'izzo.admin.uploadEvent.ticketsPlaceholder' | translate }}
              </p>
              <span class="data-value">{{
                eventInformation?.fromPrice
                  ? 'CHF ' + eventInformation?.fromPrice
                  : ('izzo.admin.uploadEvent.notSpecified' | translate)
              }}</span>
            </div>
            <div class="data">
              <p class="data-title">
                {{ 'izzo.admin.uploadEvent.linkToTicket' | translate }}
              </p>
              <span class="data-value">{{
                eventInformation?.ticketLink ||
                  'izzo.admin.uploadEvent.notSpecified' | translate
              }}</span>
            </div>
            <div class="data">
              <p class="data-title">
                {{ 'izzo.admin.uploadEvent.linkToFyraTicket' | translate }}
              </p>
              <span class="data-value">{{
                eventInformation?.fyraTicketLink ||
                  'izzo.admin.uploadEvent.notSpecified' | translate
              }}</span>
            </div>
          </div>
        </div>
        <div class="data-wrapper">
          <h2 class="form-title">
            {{ 'izzo.admin.uploadEvent.boosters' | translate }}
          </h2>
          <div class="products">
            @if (!anyProductSelected()) {
              <div class="data">
                <span class="data-value">{{
                  'izzo.admin.uploadEvent.notSpecified' | translate
                }}</span>
              </div>
            } @else {
              @for (product of products; track product) {
                @if (product.selected) {
                  <izzo-product
                    (selectionToggled)="emitSelectionToggled(product)"
                    [product]="product"
                  ></izzo-product>
                }
              }
            }
          </div>
        </div>
        <div class="form-group-buttons">
          <izzo-button
            (click)="emitLastStepClicked()"
            [label]="'izzo.admin.uploadEvent.back' | translate"
            variant="secondary"
          ></izzo-button>
          <izzo-button
            (click)="emitUploadClicked()"
            [label]="'izzo.admin.uploadEvent.upload' | translate"
          ></izzo-button>
        </div>
      </div>
    }
  </div>
</div>
