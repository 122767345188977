<div [class.background--open]="open" class="background">
  <div [ngClass]="{'wider': wider}" aria-labelledby="dialog-title" aria-modal="true" class="dialog" role="dialog">
    <div (click)="emitClickCancel()" class="close">
      <svg-icon key="close"></svg-icon>
    </div>
    @if (title) {
      <h2 class="title" id="dialog-title">{{ title }}</h2>
    }
    @if (main) {
      <p [innerHTML]="main" class="main"></p>
    }
    <ng-content></ng-content>
    <div class="footer">
      <ng-content select="izzo-button"></ng-content>
      @if (withCloseButton) {
        <izzo-button
          (click)="emitClickCancel()"
          [isFullwidth]="true"
          class="cancel-button"
          label="{{ 'izzo.admin.dialog.close' | translate }}"
          variant="secondary"
          >
        </izzo-button>
      }
    </div>
  </div>
</div>
