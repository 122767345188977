import { List, ListInvitee } from '../models/app.models';
import { ElementRef, EventEmitter } from '@angular/core';

export function importFileChange(
  importInput: ElementRef<HTMLInputElement>,
  listInvitees: ListInvitee[],
  list: List,
  maxInviteesReached: EventEmitter<void>,
  importCsv: EventEmitter<File>,
): void {
  const files = importInput.nativeElement.files;
  const file = files?.item(0);

  if (!file) {
    return;
  }

  if (listInvitees?.length! == list?.maxInvitees!) {
    maxInviteesReached.emit();
  } else {
    importCsv.emit(file);
  }
}
