import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, NgControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'touchedErrors',
  pure: false, // This is important to update the pipe when the form control changes
})
export class TouchedErrorsPipe implements PipeTransform {
  transform(
    formField: FormControl | FormGroup | NgControl,
  ): Record<string, string> | null {
    if (formField.untouched) {
      return null;
    }

    return formField.errors;
  }
}
