<div class="typeahead">
  <div (clickOutside)="close()" class="typeahead">
    <label [for]="id" class="form-field-label">{{
      'izzo.admin.shared.plz' | translate
    }}</label>
    <input
      #zipInput
      (blur)="doBlur()"
      (click)="openOnFocus($event)"
      (focus)="openOnFocus($event)"
      (input)="input()"
      [id]="id"
      [ngClass]="{ error: errorMessage }"
      [placeholder]="'izzo.admin.shared.plz' | translate"
      autocomplete="off"
      class="typeahead__field"
      maxlength="4"
      type="tel"
    />
  </div>
  @if (errorMessage) {
    <span class="error-message">
      {{ errorMessage }}
    </span>
  }
  @if (!errorMessage && zipControl && zipControl.errors) {
    <span class="error-message">
      {{ zipControl | translateError }}
    </span>
  }
  @if ((hasItems || noResultsFound) && isOpen) {
    <ul
      #autocompleteContainer
      class="typeahead-list"
      id="resultListId"
      role="listbox"
    >
      @if (noResultsFound) {
        <li class="typeahead-list--empty">
          {{ 'izzo.admin.shared.noPlaceFound' | translate }}
        </li>
      }
      @for (item of filteredLocations; track item) {
        <li>
          <button
            (click)="selectItem(item)"
            class="typeahead-list__btn"
            role="option"
            type="button"
          >
            <span>{{ item.plz }}, {{ item.province }}</span>
          </button>
        </li>
      }
    </ul>
  }
</div>
