import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Reservation } from '../../../core/models/app.models';
import { FormGroup } from '@angular/forms';
import { ReservationsFilterFormGroup } from '../../../core/models/form.models';

@Component({
  selector: 'izzo-all-reservations',
  templateUrl: './allReservations.component.html',
  styleUrls: ['./allReservations.component.scss'],
})
export class AllReservationsComponent {
  @Input() reservations!: Reservation[] | null;
  @Input() filterReservationsFormGroup!: FormGroup<ReservationsFilterFormGroup>;
  @Output() backClicked = new EventEmitter<void>();

  page: number = 1;

  get visibleReservations() {
    if (this.reservations) {
      return this.reservations.filter((reservation) => {
        const searchString =
          this.filterReservationsFormGroup.controls.filterContains.value;
        return (
          !searchString ||
          reservation
            .name!.toLowerCase()
            .includes(searchString.toLowerCase()) ||
          reservation
            .email!.toLowerCase()
            .includes(searchString.toLowerCase()) ||
          reservation.name!.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    }
    return [];
  }

  emitBackClicked() {
    this.backClicked.emit();
  }
}
