import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'izzo-filter-badge',
  templateUrl: './filter-badge.component.html',
  styleUrls: ['./filter-badge.component.scss'],
})
export class FilterBadgeComponent {
  @Input() isChecked: boolean = false;
  @Output() toggleFilterClicked = new EventEmitter<number>();

  emitFilterToggle() {
    this.toggleFilterClicked.emit();
  }
}
