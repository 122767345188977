<div class="reservation-request">
  <div class="header">
    <h2>{{ 'izzo.admin.orbit.reservationRequestTitle' | translate }}</h2>
    <svg-icon (click)="close()" key="close" />
  </div>
  <div class="reservation-request__main">
    <div class="form-wrapper">
      <div class="data-wrapper">
        <div class="data">
          <p class="data-title">
            {{ 'izzo.admin.orbit.reservation.nameLabel' | translate }}
          </p>
          <span class="data-value">{{ reservation?.name }}</span>
        </div>
        <div class="data">
          <p class="data-title">
            {{ 'izzo.admin.orbit.reservation.emailLabel' | translate }}
          </p>
          <span class="data-value">{{ reservation?.email }}</span>
        </div>
        <div class="data">
          <p class="data-title">
            {{ 'izzo.admin.orbit.reservation.telephoneLabel' | translate }}
          </p>
          <span class="data-value">{{ reservation?.telephone }}</span>
        </div>
        <div class="data">
          <p class="data-title">
            {{ 'izzo.admin.orbit.reservation.numberOfGuestsLabel' | translate }}
          </p>
          <span class="data-value">{{ reservation?.numberPersons }}</span>
        </div>
        <div class="data">
          <p class="data-title">
            {{ 'izzo.admin.orbit.reservation.arrivalLabel' | translate }}
          </p>
          <span class="data-value">{{ reservation?.arrival }}</span>
        </div>
        <div class="data">
          <p class="data-title">
            {{ 'izzo.admin.orbit.reservation.additonalInfoLabel' | translate }}
          </p>
          <span class="data-value">{{ reservation?.comment }}</span>
        </div>
        <izzo-lounge-dropdown
          [errorMessage]="
            reservationRequestFormGroup.controls.lounge
              | touchedErrors
              | firstError
              | translate
          "
          [formControl]="reservationRequestFormGroup.controls.lounge"
          [label]="'izzo.admin.orbit.reservation.assignLoungeLabel' | translate"
          [lounges]="availableLounges"
          [nothingChosenText]="
            'izzo.admin.uploadEvent.nothingChosen' | translate
          "
          class="input"
          id="lounge"
        >
        </izzo-lounge-dropdown>
        <div class="data">
          <p class="data-title">
            {{ 'izzo.admin.orbit.reservation.internCommentLabel' | translate }}
          </p>
          <izzo-input-textarea
            [errorMessage]="
              reservationRequestFormGroup.controls.internComment
                | touchedErrors
                | firstError
                | translate
            "
            [formControl]="reservationRequestFormGroup.controls.internComment"
            [placeholder]="
              'izzo.admin.orbit.reservation.internCommentLabel' | translate
            "
            [variant]="'dark'"
            id="internComment"
          >
          </izzo-input-textarea>
        </div>

        <div class="data">
          <p class="data-title">
            {{ 'izzo.admin.orbit.reservation.messageLabel' | translate }}
          </p>
          <izzo-input-textarea
            [errorMessage]="
              reservationRequestFormGroup.controls.message
                | touchedErrors
                | firstError
                | translate
            "
            [formControl]="reservationRequestFormGroup.controls.message"
            [placeholder]="
              'izzo.admin.orbit.reservation.messageLabel' | translate
            "
            [variant]="'dark'"
            id="message"
          >
          </izzo-input-textarea>
        </div>
      </div>
    </div>
    <div class="reservation-request__main__statusForm">
      <div class="reservation-request__main__statusForm-wrapper">
        <div class="reservation-request__main__statusForm-buttons">
          <izzo-button
            (buttonClicked)="emitCancelReservationClicked()"
            [label]="'izzo.admin.orbit.reservation.cancelLabel' | translate"
            [variant]="'danger'"
          >
          </izzo-button>
          <izzo-button
            [label]="'izzo.admin.orbit.reservation.assignLabel' | translate"
            (buttonClicked)="emitAssignReservationClicked()"
            [variant]="'success'"
          >
          </izzo-button>
        </div>
      </div>
    </div>
  </div>
</div>
