import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { Location } from '../../../core/models/app.models';
import { compareSearchResults } from '../../../core/helpers/helpers';

@Component({
  selector: 'izzo-location-typeahead',
  templateUrl: './location-typeahead.component.html',
  styleUrls: ['./location-typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationTypeaheadComponent),
      multi: true,
    },
  ],
})
export class LocationTypeaheadComponent
  implements OnChanges, ControlValueAccessor
{
  @Input() label!: string;
  @Input() id!: string;
  @Input() formControl!: UntypedFormControl;
  @Input() locations: any[] | null = [];
  @Input() errorMessage!: string | null;
  @Input() newLocationText!: string;

  searchString = '';
  selectedItem: any | undefined;
  showLocations: Location[] | null = [];
  isListVisible = true;

  constructor() {}

  get hasValue(): boolean {
    return !!this.searchString;
  }

  onChange: any = () => {};

  onTouched = () => {};

  ngOnChanges(changes: SimpleChanges): void {
    if ('locations' in changes) {
      if (this.formControl.value === -1) {
        this.selectedItem = -1;
        this.searchString = this.newLocationText;
      } else if (this.formControl.value > 0) {
        const selectedLocation = this.locations?.find(
          (location) => location.id === this.formControl.value,
        );
        this.selectedItem = selectedLocation?.id;
        this.searchString = this.mapName(selectedLocation);
        this.onChange(this.selectedItem);
      }
      this.isListVisible = false;
    }
  }

  filter(): void {
    if (!this.locations) {
      return;
    }

    this.showLocations = this.locations.filter((location) =>
      this.mapName(location)
        .toLowerCase()
        .includes(this.searchString.toLowerCase()),
    );
    this.showLocations.sort((a, b) =>
      compareSearchResults(this.mapName(a), this.mapName(b), this.searchString),
    );
  }

  selectItem(item: Location): void {
    this.selectedItem = item.id;
    this.searchString = this.mapName(item);
    this.onChange(item.id);
  }

  selectNewLocation(): void {
    this.selectedItem = -1;
    this.searchString = this.newLocationText;
    this.onChange(-1);
  }

  mapName(location: Location): string {
    if (!location) {
      return '';
    }

    return (
      location.name +
      ', ' +
      location.street +
      ', ' +
      location.place?.plz +
      ' ' +
      location.place?.province
    );
  }

  registerOnChange(onChanged: any): void {
    this.onChange = onChanged;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  async writeValue(val: Location): Promise<void> {
    const selectedAddress = this.locations?.find(
      (location) => location.id === val.id,
    );
    if (selectedAddress) {
      this.selectedItem = selectedAddress;
      this.searchString = this.mapName(selectedAddress);
    }
  }

  markAsTouched(): void {
    this.onTouched();
  }
}
