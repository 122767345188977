import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { Label } from '../../../core/models/app.models';

@Component({
  selector: 'izzo-organizer-dropdown',
  templateUrl: './organizer-dropdown.component.html',
  styleUrls: ['./organizer-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganizerDropdownComponent),
      multi: true,
    },
  ],
})
export class OrganizerDropdownComponent
  implements ControlValueAccessor, OnInit
{
  @Input() label: string | undefined;
  @Input() id!: string;
  @Input() placeholder!: string;
  @Input() nothingChosenText!: string;
  @Input() formControl!: UntypedFormControl;
  @Input() errorMessage!: string | null;
  @Input() internalLabels!: Label[] | null;
  @Input() defaultValue!: any | null;

  hasValue: boolean | undefined;

  onChange: any = () => {};

  ngOnInit(): void {
    this.checkValue();
  }

  checkValue(): void {
    this.hasValue = this.formControl.value !== null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: string): void {
    if (val !== this.formControl.value) {
      this.formControl.setValue(val);
      this.onChange(val);
    }
  }
}
