import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Zip } from '../../../core/models/app.models';
import { startWith, Subscription } from 'rxjs';

@Component({
  selector: 'izzo-zip-typeahead',
  templateUrl: './zip-typeahead.component.html',
  styleUrl: './zip-typeahead.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZipTypeaheadComponent implements OnChanges, OnInit, OnDestroy {
  @Input() label!: string;
  @Input() id!: string;
  @Input() zipControl!: UntypedFormControl;
  @Input() zips: Zip[] | null = [];
  @Input() errorMessage!: string | null;
  @Input() minFilterCharacters = 3;
  @Input() initialValue!: Zip;

  name: string = '';
  hasFocus = false;
  isOpen = false;
  filteredLocations!: Zip[];
  noResultsFound = false;
  controlSubscription!: Subscription;

  @ViewChild('zipInput', { static: true })
  zipInput!: ElementRef;

  @ViewChild('autocompleteContainer', { static: true })
  autocompleteContainer!: ElementRef;

  get content() {
    return (this.zipInput.nativeElement as HTMLInputElement).value;
  }

  set content(zip: string) {
    (this.zipInput.nativeElement as HTMLInputElement).value = zip;
  }

  get hasItems() {
    return this.filteredLocations && this.filteredLocations.length > 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['zips'] && this.zips) {
      this.filterLocations();
    }
  }

  ngOnInit(): void {
    this.controlSubscription = this.zipControl.valueChanges
      .pipe(startWith(true))
      .subscribe(() => {
        if (this.zipControl.value?.plz && this.zipControl.value?.province) {
          this.content = `${this.zipControl.value.plz}, ${this.zipControl.value.province}`;
        }
      });

    if (this.initialValue) {
      if (
        this.initialValue.id > 0 ||
        (this.initialValue.plz && this.initialValue.province)
      ) {
        this.content = `${this.initialValue.plz}, ${this.initialValue.province}`;
      }
    }
  }

  ngOnDestroy() {
    this.controlSubscription.unsubscribe();
  }

  filterLocations() {
    if (this.content.length < this.minFilterCharacters) {
      this.filteredLocations = [];
    } else {
      this.filteredLocations = this.zips
        ? this.zips.filter(
            (location) => location.plz.toString().indexOf(this.content) === 0,
          )
        : [];
      this.noResultsFound =
        !!this.content && this.filteredLocations.length === 0;
    }
  }

  input() {
    this.focus();
    this.open();
    this.filterLocations();
  }

  openOnFocus(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.focus();
    this.open();
  }

  doBlur() {
    if (this.hasFocus) {
      this.hasFocus = false;
    }
  }

  selectItem(item: Zip) {
    if (item) {
      this.zipControl.setValue(item);
      (this.zipInput.nativeElement as HTMLInputElement).value =
        item.plz.toString() + ', ' + item.province.toString();
      this.name = item.province;
    } else {
      (this.zipInput.nativeElement as HTMLInputElement).value = '';
      this.name = '';
      this.zipControl.setValue('');
    }
    this.zipControl.markAsDirty();
    this.zipControl.markAsTouched();

    this.close();
    this.doBlur();
  }

  close() {
    if (this.isOpen) {
      this.isOpen = false;
    }
  }

  private findItemById(id: number) {
    return this.zips!.find((it) => it.id === id);
  }

  private findItemByPlzAndProvince(plz: string, province: string) {
    return this.zips!.find((it) => it.plz === plz && it.province === province);
  }

  private open() {
    if (!this.isOpen) {
      this.isOpen = true;
    }
  }

  private focus() {
    if (!this.hasFocus) {
      this.hasFocus = true;

      const zipInputElement = this.zipInput.nativeElement as HTMLInputElement;
      zipInputElement.focus();
      zipInputElement.select();
    }
  }
}
