import { Directive, ElementRef, inject, Input, OnInit } from '@angular/core';

@Directive({ selector: 'input [izzoAutofocus]' })
export class AutofocusDirective implements OnInit {
  @Input('izzoAutofocus')
  public autofocus = false;

  private readonly elementRef: ElementRef<HTMLInputElement> = inject(
    ElementRef<HTMLInputElement>,
  );

  public ngOnInit(): void {
    if (this.autofocus) {
      this.elementRef.nativeElement.focus();
    }
  }
}
