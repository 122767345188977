<label class="custom-image-input" for="image-input">
  {{ 'izzo.admin.uploadEvent.upload' | translate }}
</label>
<input
  (change)="fileChangeEvent($event)"
  accept="image/*"
  class="image-input"
  id="image-input"
  type="file"
/>

<div class="image-preview-wrap">
  @if (image) {
    <img [src]="image" alt="Image Preview" class="image-preview" />
  }
</div>

@if (showDialog) {
  <izzo-dialog
    (closeDialog)="closeDialog()"
    [main]="dialogDescription"
    [open]="showDialog"
    [title]="dialogTitle"
    [wider]="false"
    [withCloseButton]="true"
  >
    <image-cropper
      class="cropper"
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="loadImageFailed()"
      [aspectRatio]="1"
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [resizeToHeight]="400"
      [resizeToWidth]="400"
      backgroundColor="#241f2a"
      format="png"
      output="base64"
    ></image-cropper>
    <izzo-button
      (click)="closeDialog()"
      [isFullwidth]="true"
      label="Speichern"
    />
  </izzo-dialog>
}
