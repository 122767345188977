<div class="input-text">
  @if (label) {
    <label [for]="id" class="form-field-label">{{ label }}</label>
  }
  <div class="field-wrap">
    @if (isPrefixed()) {
      <div class="prefix">
        @if (initialType === 'email') {
          <svg-icon class="prefix-icon" key="email"></svg-icon>
        }
        @if (initialType === 'tel') {
          <svg-icon class="prefix-icon" key="tel"></svg-icon>
        }
      </div>
    }
    <input
      (change)="checkValue()"
      [formControl]="formControl"
      [id]="id"
      [izzoAutofocus]="autofocus"
      [ngClass]="{ error: errorMessage }"
      [placeholder]="placeholder"
      [readOnly]="readonly"
      [type]="type"
      class="input-text__field {{ variant }}"
    />
    @if (initialType === 'password') {
      <div class="suffix">
        @if (hide) {
          <svg-icon
            (click)="toggleHide()"
            class="suffix-icon"
            key="eye-opened"
          ></svg-icon>
        }
        @if (!hide) {
          <svg-icon
            (click)="toggleHide()"
            class="suffix-icon"
            key="eye-closed"
          ></svg-icon>
        }
      </div>
    }
  </div>
  @if (errorMessage) {
    <span class="input-text__error-message"> {{ errorMessage }} </span>
  }
  @if (!errorMessage && formControl.errors && formControl.touched) {
    <span class="input-text__error-message">
      {{ formControl | translateError }}
    </span>
  }
</div>
