import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'izzo-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  @Input() title!: string;
  @Input() main!: string;
  @Input() open!: boolean;
  @Input() withCloseButton!: boolean;
  @Input() wider!: boolean;
  @Input() buttonLabel!: string;
  @Input() buttonGroupClass = 'button-group--center';

  @Output() closeDialog = new EventEmitter<void>();

  emitClickCancel() {
    this.closeDialog.emit();
  }
}
