import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonTypes } from '../../../core/models/app.models';

@Component({
  selector: 'izzo-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() label!: string;
  @Input() variant: ButtonTypes = 'primary';
  @Input() isSmall: boolean = false;
  @Input() isFullwidth: boolean = false;
  @Input() disabled = false;
  @Output() buttonClicked = new EventEmitter<void>();

  constructor() {}

  emitButtonClicked() {
    this.buttonClicked.emit();
  }
}
