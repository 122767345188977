<div class="header">
  <div class="header-wrap">
    <div class="meta-header">
      <div class="meta-wrap">
        <span class="application-name">
          ADMIN PORTAL
        </span>
        <div class="meta-header__items">
          <span (click)="emitLogoutClicked()" class="contact-link">{{ logoutLabel }}</span>
          <a class="contact-link" href="https://izzo-app.com/contact" target="_blank">{{ contactLabel }}</a>
          <div class="language">
            @for (language of languages; track language) {
              <span (click)="emitLanguageClicked(language)"
                [ngClass]="{'active': currentLanguage === language}"
                class="language__link"
                >
                {{ language }}
              </span>
            }
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{'open-menu': mobileMenuActive}" class="header__block">
      <div class="header__block-wrap">
        <div class="header__block-logo-group">
          <a [routerLink]="[currentLanguage, 'my-events']" class="header__block-logo">
            <img alt="IZZO Logo" src="assets/images/FLORAL_WHITE_PLAIN_IZZO_LOGO.svg"/>
          </a>
        </div>
        <div class="header__block-navigation">
          @if (false) {
            <a [routerLink]="[currentLanguage, 'dashboard']" class="header__block-navigation-link">
              {{ dashboardLabel }}
            </a>
          }
          @if (getSubscriptionActive()) {
            <a
              [routerLink]="[currentLanguage, 'my-employees']"
              class="header__block-navigation-link">
              {{ 'izzo.admin.header.myEmployees' | translate }}
            </a>
          }
          @if (getSubscriptionActive()) {
            <a
              [routerLink]="[currentLanguage, 'crm']"
              class="header__block-navigation-link">
              CRM
            </a>
          }
          <a [routerLink]="[currentLanguage, 'my-events']" class="header__block-navigation-link">
            {{ myEventsLabel }}
          </a>
          @if (getSubscriptionActive()) {
            <a [routerLink]="[currentLanguage, 'club']"
              class="header__block-navigation-link">
              {{ clubLabel }}
            </a>
          }
          <a [routerLink]="[currentLanguage, 'my-profile']" class="header__block-navigation-link">
            {{ profileLabel }}
          </a>
          @if (roleSufficient) {
            <izzo-button (buttonClicked)="emitUploadEventClicked()"
            [label]="uploadEventsLabel"></izzo-button>
          }
        </div>
        <button (click)="toggleMobileMenu()" [ngClass]="{'is-active': mobileMenuActive}"
          class="hamburger hamburger--slider hidden-big" type="button">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    @if (mobileMenuActive) {
      <izzo-mobile-menu (languageClicked)="emitLanguageClicked($event)"
        [currentLanguage]="currentLanguage"
        [languages]="languages"
        [roles]="roles"
      [subscription]="subscription"></izzo-mobile-menu>
    }
  </div>
</div>
