import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Lounge } from '../../../core/models/app.models';
import { FormGroup } from '@angular/forms';
import { LoungesFilterFormGroup } from '../../../core/models/form.models';

@Component({
  selector: 'izzo-all-lounges',
  templateUrl: './allLounges.component.html',
  styleUrls: ['./allLounges.component.scss'],
})
export class AllLoungesComponent {
  @Input() lounges!: Lounge[] | null;
  @Input() filterLoungesFormGroup!: FormGroup<LoungesFilterFormGroup>;
  @Output() backClicked = new EventEmitter<void>();
  @Output() createLoungeClicked = new EventEmitter<void>();

  page: number = 1;

  get visibleLounges() {
    if (this.lounges) {
      return this.lounges.filter((lounge) => {
        const searchString =
          this.filterLoungesFormGroup.controls.filterContains.value;
        return (
          !searchString ||
          lounge.name!.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    }
    return [];
  }

  emitBackClicked() {
    this.backClicked.emit();
  }

  emitCreateLoungeClicked() {
    this.createLoungeClicked.emit();
  }
}
