import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';

@Component({
  selector: 'izzo-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
  ],
})
export class InputTextComponent implements ControlValueAccessor, OnInit {
  @Input() label: string | undefined;
  @Input() id!: string;
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() formControl!: UntypedFormControl;
  @Input() errorMessage?: string | null;
  @Input() variant: 'light' | 'dark' = 'light';
  @Input() autofocus = false;
  @Input() readonly = false;
  @Input() disabled = false;

  hasValue: boolean | undefined;
  initialType = 'text';
  hide = true;
  prefixedTypes = ['email', 'tel'];

  onChange: any = () => {};

  ngOnInit(): void {
    this.initialType = this.type;
    this.checkValue();
  }

  checkValue(): void {
    this.hasValue = this.formControl?.value !== '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: string): void {
    if (val !== this.formControl?.value) {
      this.formControl?.setValue(val);
      this.onChange(val);
    }
  }

  isPrefixed() {
    return this.prefixedTypes.includes(this.initialType);
  }

  toggleHide() {
    if (this.hide) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }

    this.hide = !this.hide;
  }
}
