import { Component, Input } from '@angular/core';
import { ButtonTypes } from '../../../core/models/app.models';

@Component({
  selector: 'izzo-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss'],
})
export class ButtonLinkComponent {
  @Input() label!: string;
  @Input() variant: ButtonTypes = 'primary';
  @Input() isSmall: boolean = false;
  @Input() isFullwidth: boolean = false;
  @Input() link!: string;

  constructor() {}
}
