import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  LoungeFormGroup,
  ReservationFormGroup,
  ReservationRequestFormGroup,
} from '../../../core/models/form.models';
import { Lounge, LoungeType } from 'src/app/core/models/app.models';
import { getLoungeTypeById } from 'src/app/core/helpers/helpers';

@Component({
  selector: 'izzo-create-edit-lounge-form',
  templateUrl: './create-edit-lounge-form.component.html',
  styleUrls: ['./create-edit-lounge-form.component.scss'],
})
export class CreateEditLoungeFormComponent {
  @Input() loungeFormGroup!: FormGroup<LoungeFormGroup>;
  @Input() reservationFormGroup!: FormGroup<ReservationFormGroup>;
  @Input() event!: Event | null;
  @Input() lounge!: Lounge | null;
  @Input() loungeTypes!: LoungeType[] | null;
  @Output() uploadClicked = new EventEmitter<void>();
  @Output() saveReservationClicked = new EventEmitter<void>();

  getLounge(loungeTypeId: number) {
    const loungeType = getLoungeTypeById(loungeTypeId, this.loungeTypes!);
    if (loungeTypeId === -1) {
      return this.loungeFormGroup?.controls.loungeType.value;
    }
    return loungeType?.name;
  }

  emitUploadLoungeClicked() {
    this.uploadClicked.emit();
  }

  emitSaveReservationClicked() {
    this.saveReservationClicked.emit();
  }
}
