<div class="allInvitees">
  <div class="back">
    <izzo-button (click)="emitBackClicked()" [isFullwidth]="true" [isSmall]="true">{{ 'izzo.admin.list.backToOrbitOverview' | translate }}</izzo-button>
  </div>
  <h2>{{'izzo.admin.orbit.allInvitees' | translate}}</h2>
  <div class="search">
    <izzo-input-text
      [formControl]="filterListInviteesFormGroup.controls.filterContains"
      [errorMessage]="filterListInviteesFormGroup.controls.filterContains | touchedErrors | firstError | translate"
      variant="dark"
    [label]="'izzo.admin.shared.searchPlaceholder' | translate" [placeholder]="'izzo.admin.shared.searchPlaceholder' | translate"></izzo-input-text>
  </div>
  <div class="table-wrap">
    <table>
      @if (visibleInvitees.length > 0) {
        <div>
          <thead>
            <tr class="table-header">
              <th class="clickable-th">{{ 'izzo.admin.list.name' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.list.firstName' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.list.comment' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.list.checkIn' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.list.listName' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            @for (invitee of visibleInvitees | paginate: { itemsPerPage: 15, currentPage: page }; track invitee) {
              <tr>
                <ng-container>
                  <td class="hidden-small">{{ invitee.name }}</td>
                  <td class="hidden-small">{{ invitee.firstName }}</td>
                  <td class="hidden-small">{{ invitee.comment }}</td>
                  <td class="hidden-small">
                    <izzo-toggle-switch [selected]="invitee.isCheckedIn! === true" (switchClicked)="emitCheckInOutInvitee(invitee)">
                    </izzo-toggle-switch>
                  </td>
                  <td class="hidden-small">{{ invitee.list?.name }}</td>
                </ng-container>
              }
            </tbody>
          </div>
        }
      </table>
      <div class="pagination">
        <pagination-controls
          class="pagination-control"
          [autoHide]="true"
          [responsive]="true"
          nextLabel="{{'izzo.admin.pagination.nextLabel' | translate }}"
          previousLabel="{{'izzo.admin.pagination.previousLabel' | translate }}"
        ></pagination-controls>
      </div>
    </div>
