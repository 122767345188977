import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export type Steps = string[];

@Component({
  selector: 'izzo-stepper',
  templateUrl: 'stepper.component.html',
  styleUrls: ['stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent {
  @Input()
  public steps: Steps = [];

  @Input()
  public activeStep = 1;

  @Output()
  public readonly stepClick = new EventEmitter<number>();
}
