import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { format, isValid, parse } from 'date-fns';
import { formatDate } from '../../../core/helpers/helpers';

@Component({
  selector: 'izzo-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateComponent),
      multi: true,
    },
  ],
})
export class InputDateComponent implements ControlValueAccessor, OnInit {
  @Input() label: string | undefined;
  @Input() id!: string;
  @Input() type!: string;
  @Input() formControl!: UntypedFormControl;
  @Input() errorMessage!: string | null;
  @Input() variant: 'light' | 'dark' = 'light';
  displayValue = '';
  dividerChar = '.';
  hasValue: boolean | undefined;
  today = new Date().getDate();

  constructor() {}

  ngOnInit(): void {
    this.hasValue = this.formControl.value !== '';
    if (this.hasValue) {
      this.displayValue = format(
        new Date(this.formControl.value),
        'dd.MM.yyyy',
      );
    }
  }

  checkValue(event: any): void {
    this.displayValue = formatDate(event, this.dividerChar);
    const inputDate = parse(this.displayValue, 'dd.MM.yyyy', new Date());
    const formattedDateString = isValid(inputDate)
      ? format(inputDate, 'yyyy-MM-dd')
      : 'InvalidDate';
    this.writeValue(formattedDateString);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  writeValue(val: string): void {
    if (val !== this.formControl.value) {
      this.formControl.setValue(val);
      this.onChange(val);
    }
  }

  isNumber(value: any): boolean {
    return !isNaN(value.key) || value.key === '.';
  }

  onTouched(): void {
    this.onTouchedCallback();
  }

  private onChange: any = () => {};

  private onTouchedCallback: any = () => {};
}
