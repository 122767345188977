<div class="input-dropdown">
  @if (label) {
    <label [for]="id" class="form-field-label">{{ label }}</label>
  }
  <select
    (change)="checkValue()"
    [formControl]="formControl"
    [id]="id"
    [ngClass]="{ error: errorMessage }"
    class="input-dropdown__field"
  >
    <option [ngValue]="-1">
      {{ 'izzo.admin.uploadEvent.chooseLabel' | translate }}
    </option>
    @for (organizer of internalLabels; track organizer) {
      <option [ngValue]="organizer.id">
        {{ organizer.labelName }}
      </option>
    }
  </select>
  @if (errorMessage) {
    <span class="input-dropdown__error-message">
      {{ errorMessage }}
    </span>
  }
  @if (!errorMessage && formControl.errors && formControl.touched) {
    <span class="input-text__error-message">
      {{ formControl | translateError }}
    </span>
  }
</div>
