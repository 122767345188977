import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Lounge } from '../../../core/models/app.models';

@Component({
  selector: 'izzo-lounge-card',
  templateUrl: './lounge-card.component.html',
  styleUrls: ['./lounge-card.component.scss'],
})
export class LoungeCardComponent {
  @Input() lounge!: Lounge | null;
  @Input() roles!: string[] | null;
  @Output() editLoungeClicked = new EventEmitter<Lounge>();
  @Output() checkInLoungeClicked = new EventEmitter<Lounge>();
  @Output() deleteLoungeClicked = new EventEmitter<Lounge>();

  constructor() {}

  get roleSufficient() {
    return (
      (this.roles &&
        (this.roles.includes('GLOBAL_ADMIN') ||
          this.roles.includes('ASSISTANT') ||
          this.roles.includes('NIGHTMANAGER'))) ||
      false
    );
  }

  get canDelete() {
    if (!!this.lounge?.event || !!this.lounge?.location) {
      return true;
    }
    return false;
  }

  get countPersonsInLounge() {
    if (!!this.lounge?.reservation!) {
      return this.lounge?.reservation!.numberPersons!;
    } else {
      return this.lounge?.numberSeats!;
    }
  }

  emitEditLounge() {
    this.editLoungeClicked.emit(this.lounge!);
  }

  emitCheckInLounge() {
    this.checkInLoungeClicked.emit(this.lounge!);
  }

  emitDeleteLounge() {
    this.deleteLoungeClicked.emit(this.lounge!);
  }

  getLoungeStatus() {
    if (
      this.lounge &&
      this.lounge.reservation?.reservationStatus === 'assigned'
    ) {
      return 'reserved';
    } else if (
      this.lounge &&
      this.lounge.reservation?.reservationStatus === 'checked'
    ) {
      return 'checked';
    }

    return 'available';
  }
}
