import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'izzo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() currentLanguage!: string | null;
  @Input() saferPartyTitleLabel!: string;
  @Input() saferPartyTextLabel!: string;
  @Input() saferPartyButtonLabel!: string;
  @Input() contactsTitleLabel!: string;
  @Input() contactsTextLabel!: string;
  @Input() contactsButtonLabel!: string;
  @Input() servicesLink!: string;
  @Input() aboutLink!: string;
  @Input() faqLink!: string;
  @Input() mediakitLink!: string;
  @Input() saferPartyLink!: string;
  @Input() contactLink!: string;
  @Input() instagramLink!: string;
  @Input() facebookLink!: string;
  @Input() linkedInLink!: string;
  @Input() impressumLink!: string;
  @Input() agbLink!: string;
  @Input() privacyLink!: string;
  @Input() languages!: string[];
  @Output() languageClicked = new EventEmitter<string>();

  constructor() {}

  navigateTo(link: string) {
    window.open(link, '_blank');
  }

  emitLanguageClicked(language: string) {
    this.languageClicked.emit(language);
  }
}
