<div class="input-dropdown">
  @if (label) {
    <label [for]="id" class="form-field-label">{{ label }}</label>
  }

  <div class="select">
    <select
      (change)="checkValue()"
      [formControl]="formControl"
      [id]="id"
      [ngClass]="{ error: errorMessage }"
      class="input-dropdown__field"
    >
      <option [ngValue]="0">{{ nothingChosenText }}</option>
      @for (lounge of lounges; track loungeTrackByFn($index, lounge)) {
        <option [ngValue]="lounge.id" class="lounge-option">
          {{ lounge.name }}
          <svg-icon key="close" />
          {{ lounge.numberSeats }}
        </option>
      }
    </select>

    @if (selected) {
      <div class="selected">
        {{ selected.name }}
        <div class="end">
          <div class="seats">
            <svg-icon color="#FFE248" key="group" />
            {{ selected.numberSeats }}
          </div>
          <div [ngClass]="[selected.loungeStatus]" class="status">
            {{ 'izzo.admin.orbit.lounge.' + selected.loungeStatus | translate }}
          </div>
        </div>
      </div>
    }
  </div>

  @if (errorMessage) {
    <span class="input-dropdown__error-message">
      {{ errorMessage }}
    </span>
  }
  @if (!errorMessage && formControl.errors && formControl.touched) {
    <span class="input-text__error-message">
      {{ formControl | translateError }}
    </span>
  }
</div>
