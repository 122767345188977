<div class="allLists">
  <div class="back">
    <izzo-button (click)="emitBackClicked()" [isFullwidth]="true" [isSmall]="true">{{ 'izzo.admin.list.backToOrbitOverview' | translate }}</izzo-button>
  </div>
  <h2>{{'izzo.admin.orbit.allLists' | translate}}</h2>
  <div class="createList">
    <izzo-button (click)="emitCreateListClicked()" [isFullwidth]="true" [isSmall]="true">{{ 'izzo.admin.list.createList' | translate }}</izzo-button>
  </div>
  <div class="search">
    <izzo-input-text
      [formControl]="filterListFormGroup.controls.filterContains"
      variant="dark"
      [label]="'izzo.admin.shared.searchPlaceholder' | translate"
      [placeholder]="'izzo.admin.shared.searchPlaceholder' | translate"
      [errorMessage]="
                filterListFormGroup.controls.filterContains
                  | touchedErrors
                  | firstError
                  | translate
      "
    ></izzo-input-text>
  </div>
  <div class="table-wrap">
    <table>
      @if (visibleLists.length > 0) {
        <div>
          <thead>
            <tr class="table-header">
              <th class="clickable-th">{{ 'izzo.admin.list.listName' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.list.maxInvitees' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.list.namePromoter' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.list.emailPromoter' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.list.price' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.list.listType' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            @for (list of visibleLists | paginate: { itemsPerPage: 15, currentPage: page }; track list) {
              <tr>
                <ng-container>
                  <td class="hidden-small">{{ list.name }}</td>
                  <td class="hidden-small">{{ list.maxInvitees }}</td>
                  <td class="hidden-small">{{ list.namePromoter }}</td>
                  <td class="hidden-small">{{ list.emailPromoter }}</td>
                  <td class="hidden-small">{{ list.price }}</td>
                  <td class="hidden-small">{{ list.listType?.name }}</td>
                </ng-container>
              }
            </tbody>
          </div>
        }
      </table>
      <div class="pagination">
        <pagination-controls
          class="pagination-control"
          [autoHide]="true"
          [responsive]="true"
          nextLabel="{{'izzo.admin.pagination.nextLabel' | translate }}"
          previousLabel="{{'izzo.admin.pagination.previousLabel' | translate }}"
        ></pagination-controls>
      </div>
    </div>
