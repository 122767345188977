<div class="input-date">
  @if (label) {
    <label [for]="id" class="form-field-label">{{ label }}</label>
  }
  <input
    (change)="checkValue()"
    [formControl]="formControl"
    [id]="id"
    class="input-date__field {{ variant }}"
    type="date"
  />
  @if (errorMessage) {
    <span class="input-date__error-message">
      {{ errorMessage }}
    </span>
  }
  @if (!errorMessage && formControl.errors && formControl.touched) {
    <span class="input-date__error-message">
      {{ formControl | translateError }}
    </span>
  }
</div>
