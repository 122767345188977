<div class="step-menu">
  @for (step of steps; track step) {
    <div (click)="emitSetStep(step.stepId)"
      [ngClass]="{'active': step.active, 'invalid': step.state === 'invalid', 'completed': step.state === 'valid'}"
      class="step-menu__item">
      <p>{{ step.stepName }}</p>
      @if (step.state !== 'unvalidated' && !step.active) {
        <div class="step-menu__icon">
          <svg-icon [key]="getStatusIcon(step)" class="step-menu__icon-svg"></svg-icon>
        </div>
      }
    </div>
  }
</div>
