import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { List, ListInvitee } from '../../../core/models/app.models';
import {
  AddInviteeToListFormGroup,
  EditInviteeFormGroup,
  ListLinkFormGroup,
} from '../../../core/models/form.models';
import { importFileChange } from 'src/app/core/helpers/helpers-not-native';

@Component({
  selector: 'izzo-list-invitees',
  templateUrl: './list-invitees.component.html',
  styleUrls: ['./list-invitees.component.scss'],
})
export class ListInviteesComponent {
  @Input() addInviteeToListFormGroup!: FormGroup<AddInviteeToListFormGroup>;
  @Input() editInviteeFormGroup!: FormGroup<EditInviteeFormGroup>;
  @Input() listLinkFormGroup!: FormGroup<ListLinkFormGroup>;
  @Input() listNameHeaderText: string = '';
  @Input() listLink: string = '';
  @Input() list!: List | null;
  @Input() roles!: string[] | null;
  @Input() listInvitees!: ListInvitee[] | null;
  @Input() listInvitee!: ListInvitee | null;
  @Output() addInviteeToListClicked = new EventEmitter<ListInvitee>();
  @Output() editInviteeClicked = new EventEmitter<ListInvitee>();
  @Output() deleteInviteeClicked = new EventEmitter<ListInvitee>();
  @Output() confirmEditInviteeClicked = new EventEmitter<ListInvitee>();
  @Output() createNewLinkClicked = new EventEmitter<List>();
  @Output() currentTimeOverValidTime = new EventEmitter<void>();
  @Output() maxInviteesReached = new EventEmitter<void>();
  @Output() importCsv = new EventEmitter<File>();
  @Output() copyLinkClicked = new EventEmitter<void>();
  @Output() checkInOutInviteeClicked = new EventEmitter<ListInvitee>();
  @Output() backClicked = new EventEmitter<void>();
  @Output() exportCsvClick = new EventEmitter<void>();
  @Output() getCsvTemplateClick = new EventEmitter<void>();

  @ViewChild('import', { read: ElementRef })
  public readonly importInput!: ElementRef<HTMLInputElement>;

  isARecordInEdit: boolean = false;
  isMaxInviteesReached: boolean = false;
  copyTextVisible: boolean = false;
  page: number = 1;

  get roleSufficient() {
    return (
      (this.roles &&
        (this.roles.includes('GLOBAL_ADMIN') ||
          this.roles.includes('ASSISTANT') ||
          this.roles.includes('NIGHTMANAGER'))) ||
      false
    );
  }

  get allInviteesInList() {
    if (this.listInvitees != null) {
      return this.listInvitees;
    }
    return [];
  }

  get countInviteesInList() {
    if (this.listInvitees?.length! == this.list?.maxInvitees!) {
      this.isMaxInviteesReached = true;
    }
    if (this.listInvitees != null) {
      return this.listInvitees.length;
    }
    return [];
  }

  importFileChange(): void {
    importFileChange(
      this.importInput,
      this.listInvitees!,
      this.list!,
      this.maxInviteesReached,
      this.importCsv,
    );
  }

  toggleEditState() {
    this.isARecordInEdit = !this.isARecordInEdit;
  }

  emitBackClicked() {
    this.backClicked.emit();
  }

  emitExportCsvClicked() {
    this.exportCsvClick.emit();
  }

  emitGetCsvTemplateClicked() {
    this.getCsvTemplateClick.emit();
  }

  emitCopyLink() {
    this.copyLinkClicked.emit();
    this.copyTextVisible = true;
    setTimeout(() => {
      this.copyTextVisible = false;
    }, 3000);
  }

  emitEditInviteeClicked(listInvitee: ListInvitee) {
    this.toggleEditState();
    this.editInviteeClicked.emit(listInvitee);
  }

  emitAddInviteeToListClicked() {
    if (this.listInvitees?.length! == this.list?.maxInvitees!) {
      this.maxInviteesReached.emit();
    } else {
      const dateTimeNow = new Date();
      const listValidDateTime = new Date(this.list?.validTillDate!);
      const timeArray = this.list?.validTillTime!.split(':');
      listValidDateTime.setHours(+timeArray![0]);
      listValidDateTime.setMinutes(+timeArray![1]);
      if (listValidDateTime < dateTimeNow) {
        this.currentTimeOverValidTime.emit();
      } else {
        this.addInviteeToListClicked.emit({
          name: this.addInviteeToListFormGroup.controls.name.value,
          firstName: this.addInviteeToListFormGroup.controls.firstName.value,
          comment: this.addInviteeToListFormGroup.controls.comment.value,
          isCheckedIn:
            this.addInviteeToListFormGroup.controls.isCheckedIn.value,
          isReadOnly: true,
          list: this.list!,
          timeStamp: new Date().toDateString(),
        });
      }
    }
  }

  emitConfirmEditInviteeClicked(listInvitee: ListInvitee) {
    this.toggleEditState();
    this.confirmEditInviteeClicked.emit(listInvitee);
  }

  emitDeleteInviteeClicked(listInvitee: ListInvitee) {
    this.deleteInviteeClicked.emit(listInvitee);
  }

  emitCreateNewLink(list: List) {
    this.createNewLinkClicked.emit(list);
  }

  emitCheckInOutInvitee(listInvitee: ListInvitee) {
    this.checkInOutInviteeClicked.emit(listInvitee);
  }
}
