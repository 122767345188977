<div class="allReservations">
  <div class="back">
    <izzo-button (click)="emitBackClicked()" [isFullwidth]="true" [isSmall]="true">{{ 'izzo.admin.list.backToOrbitOverview' | translate }}</izzo-button>
  </div>
  <h2>{{'izzo.admin.orbit.allReservations' | translate}}</h2>
  <div class="search">
    <izzo-input-text
      [formControl]="filterReservationsFormGroup.controls.filterContains"
      [errorMessage]="filterReservationsFormGroup.controls.filterContains | touchedErrors | firstError | translate"
      variant="dark"
      [label]="'izzo.admin.shared.searchPlaceholder' | translate"
      [placeholder]="'izzo.admin.shared.searchPlaceholder' | translate">

    </izzo-input-text>
  </div>
  <div class="table-wrap">
    <table>
      @if (visibleReservations.length > 0) {
        <div>
          <thead>
            <tr class="table-header">
              <th class="clickable-th">{{ 'izzo.admin.orbit.reservation.nameLabel' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.orbit.reservation.emailLabel' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.orbit.reservation.telephoneLabel' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.orbit.reservation.numberOfGuestsLabel' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.orbit.reservation.arrivalLabel' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.orbit.reservation.additonalInfoLabel' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.orbit.reservation.status' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            @for (reservation of visibleReservations | paginate: { itemsPerPage: 15, currentPage: page }; track reservation) {
              <tr>
                <ng-container>
                  <td class="hidden-small">{{ reservation.name }}</td>
                  <td class="hidden-small">{{ reservation.email }}</td>
                  <td class="hidden-small">{{ reservation.telephone }}</td>
                  <td class="hidden-small">{{ reservation.numberPersons }}</td>
                  <td class="hidden-small">{{ reservation.arrival }}</td>
                  <td class="hidden-small">{{ reservation.comment }}</td>
                  <td class="hidden-small">{{ reservation.reservationStatus }}</td>
                </ng-container>
              }
            </tbody>
          </div>
        }
      </table>
      <div class="pagination">
        <pagination-controls
          class="pagination-control"
          [autoHide]="true"
          [responsive]="true"
          nextLabel="{{'izzo.admin.pagination.nextLabel' | translate }}"
          previousLabel="{{'izzo.admin.pagination.previousLabel' | translate }}"
        ></pagination-controls>
      </div>
    </div>
