import { Component, EventEmitter, Input, Output } from '@angular/core';
import { List } from '../../../core/models/app.models';
import { FormGroup } from '@angular/forms';
import { ListFilterFormGroup } from '../../../core/models/form.models';

@Component({
  selector: 'izzo-all-lists',
  templateUrl: './allLists.component.html',
  styleUrls: ['./allLists.component.scss'],
})
export class AllListsComponent {
  @Input() lists!: List[] | null;
  @Input() filterListFormGroup!: FormGroup<ListFilterFormGroup>;
  @Output() backClicked = new EventEmitter<void>();
  @Output() createListClicked = new EventEmitter<void>();

  page: number = 1;

  get visibleLists() {
    if (this.lists) {
      return this.lists.filter((list) => {
        const searchString =
          this.filterListFormGroup.controls.filterContains.value;
        return (
          !searchString ||
          list.name!.toLowerCase().includes(searchString.toLowerCase()) ||
          list.namePromoter!.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    }
    return [];
  }

  emitBackClicked() {
    this.backClicked.emit();
  }

  emitCreateListClicked() {
    this.createListClicked.emit();
  }
}
