import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';

@Component({
  selector: 'izzo-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {
  @Input() label: string | undefined;
  @Input() id!: string;
  @Input() placeholder!: string;
  @Input() type!: string;
  @Input() formControl!: UntypedFormControl;
  @Input() errorMessage!: string | null;
  @Input() variant: 'light' | 'dark' = 'light';
  hasValue: boolean | undefined;

  constructor() {}

  onChange: any = () => {};

  ngOnInit(): void {
    this.checkValue();
  }

  checkValue(): void {
    this.hasValue = this.formControl.value !== '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: string): void {
    if (val !== this.formControl.value) {
      this.formControl.setValue(val);
      this.onChange(val);
    }
  }
}
