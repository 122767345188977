<div class="product">
  <div [ngClass]="{'is-selected': product.selected}" class="product__information">
    <img [src]="product.icon" alt="Test"
         class="product__information-image">
    <h2
      class="product__information-title">{{ 'izzo.admin.uploadEvent.product.' + product.key + '.title' | translate }}</h2>
    <p
      class="product__information-description">{{ 'izzo.admin.uploadEvent.product.' + product.key + '.description' | translate }}</p>
    <p
      class="product__information-price">{{ getPrice() ? getPrice() : ('izzo.admin.uploadEvent.product.free' | translate) }}</p>
  </div>
  <div class="product__button">
    <izzo-button (click)="emitSelectionToggled()" [isSmall]="true"
                 label="{{ 'izzo.admin.uploadEvent.product.' + getSelection() + '.' + product.key | translate }}"></izzo-button>
  </div>
</div>
