<div class="input-dropdown">
  @if (label) {
    <label [for]="id" class="form-field-label">{{ label }}</label>
  }
  <select
    (change)="checkValue()"
    [formControl]="formControl"
    [id]="id"
    [ngClass]="{ error: errorMessage }"
    class="input-dropdown__field {{ variant }}"
  >
    <option
      *ngIf="!formControl.value || formControl.value.id === 0"
      [ngValue]="{ id: 0, region: '' }"
    >
      {{ nothingChosenText }}
    </option>
    @for (region of regions; track region) {
      <option
        [ngValue]="region"
        [selected]="region.id === formControl.value.id"
      >
        {{ region.region }}
      </option>
    }
  </select>
  @if (errorMessage) {
    <span class="input-dropdown__error-message">
      {{ errorMessage }}
    </span>
  }
  @if (!errorMessage && formControl.errors && formControl.touched) {
    <span class="input-text__error-message">
      {{ formControl | translateError }}
    </span>
  }
</div>
