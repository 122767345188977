import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'izzo-input-image',
  templateUrl: './input-image.component.html',
  styleUrls: ['./input-image.component.scss'],
})
export class InputImageComponent {
  showDialog = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  @Input() image: string | null = '';
  @Input() dialogTitle: string = '';
  @Input() dialogDescription: string = '';
  @Input() originalImage!: string | null;
  @Output() imageCroppedSuccessfully = new EventEmitter<string>();
  @Output() imageUploadedSuccessfully = new EventEmitter<string>();
  @Output() imageLoadFailed = new EventEmitter<void>();

  constructor(private sanitizer: DomSanitizer) {}

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (event.target.files.length > 0) {
      this.showDialog = true;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustResourceUrl(
      event.base64!,
    );
    this.imageCroppedSuccessfully.emit(event.base64!);
  }

  loadImageFailed() {
    this.imageLoadFailed.emit();
  }

  closeDialog() {
    this.showDialog = false;
  }
}
