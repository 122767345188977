<div class="filter">
  <div class="filter__title">
    <p>{{ 'izzo.admin.filters.title' | translate }}</p>
    <svg-icon (click)="emitCloseFilterClicked()" class="filter-close" key="close"></svg-icon>
  </div>
  <div class="filter__wrap">
    <div>
      <p class="filter__subtitle">{{ 'izzo.admin.filters.status.title' | translate }}</p>
      <div class="filter__badges">
        @for (eventStatus of eventStatuses; track eventStatus) {
          <izzo-filter-badge
            (toggleFilterClicked)="emitToggleStatusClicked(eventStatus)"
            [isChecked]="isStatusSelected(eventStatus)">
            {{ getTranslationKey(eventStatus) | translate }}
          </izzo-filter-badge>
        }
      </div>
    </div>
    <div>
      <p class="filter__subtitle">{{ 'izzo.admin.filters.genre.title' | translate }}</p>
      <div class="filter__badges">
        @for (genre of usedGenres; track genre) {
          <izzo-filter-badge
            (toggleFilterClicked)="emitToggleGenreClicked(genre.id)"
            [isChecked]="isGenreSelected(genre.id)">
            {{ genre.genre }}
          </izzo-filter-badge>
        }
      </div>
    </div>
    @if (usedTags?.length !== 0) {
      <div>
        <p class="filter__subtitle">{{ 'izzo.admin.filters.tag.title' | translate }}</p>
        <div class="filter__badges">
          @for (tag of usedTags; track tag) {
            <izzo-filter-badge
              (toggleFilterClicked)="emitToggleTagClicked(tag.id)"
              [isChecked]="isTagSelected(tag.id)">
              {{ tag.name }}
            </izzo-filter-badge>
          }
        </div>
      </div>
    }
    <div>
      <p class="filter__subtitle">{{ 'izzo.admin.filters.location.title' | translate }}</p>
      <div class="filter__badges">
        @for (usedLocation of usedLocations; track usedLocation) {
          <izzo-filter-badge
            (toggleFilterClicked)="emitToggleLocationClicked(usedLocation.id!)"
            [isChecked]="isLocationSelected(usedLocation.id!)">
            {{ usedLocation.name }}
          </izzo-filter-badge>
        }
      </div>
    </div>
    <div>
      <p class="filter__subtitle">{{ 'izzo.admin.filters.minAge.title' | translate }}</p>
      <div class="filter__badges">
        @for (minAge of minAges; track minAge) {
          <izzo-filter-badge
            (toggleFilterClicked)="emitToggleAgeClicked(minAge)"
            [isChecked]="isAgeSelected(minAge)">
            {{ minAge }}
          </izzo-filter-badge>
        }
      </div>
    </div>
    <div class="filter-button">
      <izzo-button (click)="emitFilterResetButtonClicked()" [isFullwidth]="true"
        [label]="'izzo.admin.filters.button.reset' | translate"
      variant="transparent"></izzo-button>
      <izzo-button (click)="emitFilterButtonClicked()" [isFullwidth]="true"
      [label]="'izzo.admin.filters.button.close' | translate"></izzo-button>
    </div>
  </div>
</div>
