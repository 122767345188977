<div class="multi-step-form">
  <div class="form-wrapper">
    @if (currentStep === 1) {
      <div class="form-group">
        <izzo-select-list
          (click)="emitSelectListTypeClicked(listStepOneFormGroup.controls.listType.value)"
          [customListText]="'izzo.admin.list.customListText' | translate"
          [formControl]="listStepOneFormGroup.controls.listType"
          [friendsListText]="'izzo.admin.list.friendsListText' | translate"
          [guestListText]="'izzo.admin.list.guestListText' | translate"
        ngDefaultControl></izzo-select-list>
      </div>
    }
    @if (currentStep === 2) {
      <div (keydown.enter)="emitUploadListClicked()" class="form-group">
        @if (listStepOneFormGroup.controls.listType.value === 3) {
          <izzo-input-text
            [errorMessage]="listStepTwoFormGroup.controls.name | touchedErrors | firstError | translate"
            [formControl]="listStepTwoFormGroup.controls.name"
            [label]="'izzo.admin.list.listName' | translate"
            [placeholder]="'izzo.admin.list.listName' | translate"
            id="listName"
            type="text"
            variant="dark">
          </izzo-input-text>
        }
        <izzo-input-text
          [errorMessage]="listStepTwoFormGroup.controls.maxInvitees | touchedErrors | firstError | translate"
          [formControl]="listStepTwoFormGroup.controls.maxInvitees"
          [label]="'izzo.admin.list.maxInvitees' | translate"
          [placeholder]="'izzo.admin.list.maxInvitees' | translate"
          id="maxInvitees"
          type="number"
          variant="dark">
        </izzo-input-text>
        <izzo-input-text
          [errorMessage]="listStepTwoFormGroup.controls.namePromoter | touchedErrors | firstError | translate"
          [formControl]="listStepTwoFormGroup.controls.namePromoter"
          [label]="'izzo.admin.list.namePromoter' | translate"
          [placeholder]="'izzo.admin.list.namePromoter' | translate"
          id="namePromoter"
          type="text"
          variant="dark">
        </izzo-input-text>
        <izzo-input-text
          [errorMessage]="listStepTwoFormGroup.controls.emailPromoter | touchedErrors | firstError | translate"
          [formControl]="listStepTwoFormGroup.controls.emailPromoter"
          [label]="'izzo.admin.list.emailPromoter' | translate"
          [placeholder]="'izzo.admin.list.emailPromoter' | translate"
          id="emailPromoter"
          type="email"
          variant="dark">
        </izzo-input-text>
        <izzo-input-text
          [errorMessage]="listStepTwoFormGroup.controls.price | touchedErrors | firstError | translate"
          [formControl]="listStepTwoFormGroup.controls.price"
          [label]="'izzo.admin.list.price' | translate"
          [placeholder]="'izzo.admin.list.price' | translate"
          id="price"
          type="number"
          variant="dark">
        </izzo-input-text>
        <div class="price-checkbox">
          <div
            (click)="toggleFreeEntry()"
            [ngClass]="{selected: isFreeEntry}"
            class="option-wrapper"
            inner-checkbox="true"
            role="checkbox"
            tabindex="0"
            >
            @if (isFreeEntry) {
              <svg-icon
                class="option"
                key="check"
              ></svg-icon>
            }
          </div>
          <p>{{ 'izzo.admin.list.isFreeEntry' | translate }}</p>
        </div>
        <div class="field-wrap">
          <izzo-input-date
              [errorMessage]="
                listStepTwoFormGroup.controls.validTillDate
                  | touchedErrors
                  | firstError
                  | translate
              "
              [formControl]="listStepTwoFormGroup.controls.validTillDate"
              [label]="'izzo.admin.list.validTill' | translate"
              class="input"
              id="date"
              variant="dark"
            >
          </izzo-input-date>
          <izzo-input-time
            [errorMessage]="
              listStepTwoFormGroup.controls.validTillTime
                | touchedErrors
                | firstError
                | translate
            "
            [formControl]="listStepTwoFormGroup.controls.validTillTime"
            [label]="'izzo.admin.list.validTill' | translate"
            class="input"
            id="time"
            variant="dark"
          >
          </izzo-input-time>
        </div>
        <div class="list-info">
          <svg-icon key="info"></svg-icon>
          <p class="text">{{ 'izzo.admin.list.info' | translate }}</p>
        </div>
        <div class="form-group-buttons">
          <izzo-button (click)="emitLastStepClicked()"
          [label]="'izzo.admin.list.back' | translate"></izzo-button>
          <izzo-button (click)="emitUploadListClicked()" [label]="'izzo.admin.list.createList' | translate"></izzo-button>
        </div>
      </div>
    }
  </div>
</div>
