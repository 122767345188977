import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'izzo-eventbox-empty',
  templateUrl: './eventbox-empty.component.html',
  styleUrls: ['./eventbox-empty.component.scss'],
})
export class EventboxEmptyComponent {
  @Input() roles!: string[] | null;
  @Output() uploadEventClicked = new EventEmitter<void>();

  get roleSufficient() {
    return (
      (this.roles &&
        (this.roles.includes('GLOBAL_ADMIN') ||
          this.roles.includes('ASSISTANT'))) ||
      false
    );
  }

  emitUploadEventClicked() {
    this.uploadEventClicked.emit();
  }
}
