import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  List,
  ListInvitee,
  Lounge,
  Reservation,
} from '../../../core/models/app.models';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'izzo-reservation-card',
  templateUrl: './reservation-card.component.html',
  styleUrls: ['./reservation-card.component.scss'],
})
export class ReservationCardComponent {
  @Input() reservation!: Reservation | null;
  @Output() openReservationClicked = new EventEmitter<Reservation>();

  constructor() {}

  get countPersonInReservation() {
    if (this.reservation?.numberPersons != null) {
      return this.reservation.numberPersons;
    }
    return [];
  }

  emitOpenReservation() {
    this.openReservationClicked.emit(this.reservation!);
  }
}
