<div class="list-card">
  <div class="list-card__menu">
    <izzo-button (click)="emitOpenList()" [isFullwidth]="true"
                 [isSmall]="true">{{ 'izzo.admin.orbit.viewList' | translate }}
    </izzo-button>
    <izzo-button (click)="emitEditList()" [isFullwidth]="true" [isSmall]="true"
                 variant="gradient">{{ 'izzo.admin.orbit.editList' | translate }}
    </izzo-button>
    <izzo-button (click)="emitDeleteList()" [isFullwidth]="true" [isSmall]="true"
                 variant="danger">{{ 'izzo.admin.orbit.deleteList' | translate }}
    </izzo-button>
  </div>
  <div class="list-card__invitees">
    <p>{{ countInviteesInList }} </p>
    <svg-icon class="list-card__invitees-icon" color="#FFE248" key="group"></svg-icon>
  </div>
  <div class="list-card__text">
    <h2 class="list-card__text-title">{{ list?.name }}</h2>
  </div>
</div>
