import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UploadEditEvent, Event } from '../models/app.models';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private http: HttpClient) {}

  getEvent(id: number) {
    return this.http.get<Event>(environment.api + `/event/${id}`);
  }

  uploadEvent(event: UploadEditEvent) {
    return this.http
      .post(environment.api + '/approve-event', event)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  editEvent(event: UploadEditEvent) {
    return this.http
      .put(environment.api + '/approve-event/edit', event)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  deleteEvent(eventId: number) {
    return this.http
      .delete(environment.api + `/event/${eventId}`)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  deleteApproveEvent(eventId: number) {
    return this.http.delete(
      environment.api + `/approve-event/delete/${eventId}`,
    );
  }
}
