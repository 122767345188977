<div class="typeahead">
  <div (clickOutside)="isListVisible = false" class="typeahead">
    <label [for]="id" class="form-field-label">{{
      'izzo.admin.uploadEvent.location' | translate
    }}</label>
    <input
      (blur)="markAsTouched()"
      (focus)="isListVisible = true"
      (input)="filter()"
      [(ngModel)]="searchString"
      [id]="id"
      [ngClass]="{ error: errorMessage }"
      [placeholder]="'izzo.admin.uploadEvent.location' | translate"
      class="typeahead__field"
      type="text"
    />
  </div>
  @if (errorMessage) {
    <span class="typeahead__error-message">
      {{ errorMessage }}
    </span>
  }
  @if (!errorMessage && formControl.errors && formControl.touched) {
    <span class="input-text__error-message">
      {{ formControl | translateError }}
    </span>
  }
  @if (hasValue && isListVisible) {
    <ul class="typeahead-list" id="resultListId" role="listbox">
      @if (showLocations && showLocations.length === 0) {
        <li class="typeahead-list--empty">
          {{ 'izzo.admin.shared.nothingFound' | translate }}
        </li>
      }
      <li>
        <button
          (click)="selectNewLocation()"
          class="typeahead-list__btn"
          role="option"
          type="button"
        >
          <span>{{ newLocationText }}</span>
        </button>
      </li>
      @for (item of showLocations; track item) {
        <li>
          <button
            (click)="selectItem(item)"
            class="typeahead-list__btn"
            role="option"
            type="button"
          >
            <span>{{ mapName(item) }}</span>
          </button>
        </li>
      }
    </ul>
  }
</div>
