import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListInvitee } from '../../../core/models/app.models';
import { FormGroup } from '@angular/forms';
import { ListInviteesFilterFormGroup } from '../../../core/models/form.models';

@Component({
  selector: 'izzo-all-invitees',
  templateUrl: './allInvitees.component.html',
  styleUrls: ['./allInvitees.component.scss'],
})
export class AllInviteesComponent {
  @Input() listInvitees!: ListInvitee[] | null;
  @Input() filterListInviteesFormGroup!: FormGroup<ListInviteesFilterFormGroup>;
  @Output() backClicked = new EventEmitter<void>();
  @Output() checkInOutInviteeClicked = new EventEmitter<ListInvitee>();

  page: number = 1;

  get visibleInvitees() {
    if (this.listInvitees) {
      return this.listInvitees.filter((invitee) => {
        const searchString =
          this.filterListInviteesFormGroup.controls.filterContains.value;
        return (
          !searchString ||
          invitee.name!.toLowerCase().includes(searchString.toLowerCase()) ||
          invitee.firstName!.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    }
    return [];
  }

  emitBackClicked() {
    this.backClicked.emit();
  }

  emitCheckInOutInvitee(listInvitee: ListInvitee) {
    this.checkInOutInviteeClicked.emit(listInvitee);
  }
}
