<div class="input-textarea">
  @if (label) {
    <label [for]="id" class="form-field-label">{{ label }}</label>
  }
  <textarea
    (change)="checkValue()"
    [formControl]="formControl"
    [id]="id"
    [ngClass]="{ error: errorMessage }"
    [placeholder]="placeholder"
    autosize
    class="input-textarea__field {{ variant }}"
    cols="1"
    rows="7"
  >
  </textarea>
</div>
@if (errorMessage) {
  <span class="input-textarea__error-message">
    {{ errorMessage }}
  </span>
}
@if (!errorMessage && formControl.errors && formControl.touched) {
  <span class="input-text__error-message">
    {{ formControl | translateError }}
  </span>
}
