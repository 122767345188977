<div class="mobile-menu hidden-big">
  <div class="mobile-menu__top">
    <span class="mobile-menu__top-title">ADMIN PORTAL</span>
  </div>
  @if (getSubscriptionActive()) {
    <div
      class="mobile-menu__navigations">
      <a [routerLink]="[currentLanguage, 'crm']" class="mobile-menu__navigations-item">
        CRM
      </a>
    </div>
  }
  <div class="mobile-menu__navigations">
    <a [routerLink]="[currentLanguage, 'my-events']" class="mobile-menu__navigations-item">
      {{ 'izzo.admin.header.myEvents' | translate }}
    </a>
  </div>
  @if (getSubscriptionActive()) {
    <div
      class="mobile-menu__navigations">
      <a [routerLink]="[currentLanguage, 'my-employees']" class="mobile-menu__navigations-item">
        {{ 'izzo.admin.header.myEmployees' | translate }}
      </a>
    </div>
  }
  @if (getSubscriptionActive()) {
    <div class="mobile-menu__navigations">
      <a [routerLink]="[currentLanguage, 'club']"
         class="mobile-menu__navigations-item">
        {{ 'izzo.admin.header.club' | translate }}
      </a>
    </div>
  }
  <div class="mobile-menu__navigations">
    <a [routerLink]="[currentLanguage, 'my-profile']" class="mobile-menu__navigations-item">
      {{ 'izzo.admin.header.profile' | translate }}
    </a>
  </div>
  <div class="mobile-menu__footer">
    <div class="line-wrapper">
      <hr class="mobile-menu__footer-line"/>
    </div>
    <div class="mobile-menu__footer-action">
      <a class="contact-link" href="https://izzo-app.com/contact" target="_blank">
        {{ 'izzo.admin.footer.contactsButton' | translate }}
      </a>
      <div class="languages">
        @for (language of languages; track language) {
          <span (click)="emitLanguageClicked(language)"
                [ngClass]="{'active': currentLanguage === language}"
                class="language-link"
          >
            {{ language }}
          </span>
        }
      </div>
    </div>
  </div>
</div>
