<div class="select-list">
  <div class="select-list-item">
    <button (click)="emitSelectListTypeClicked(1)" class="select-list-item__button">
      <img class="select-list-item__icon"
           src="assets/images/list-heart-logo.svg"><img>
    </button>
    <p class="select-list-item__button-text">
      {{ friendsListText ? friendsListText : '' }}
    </p>
  </div>
  <div class="select-list-item">
    <button (click)="emitSelectListTypeClicked(2)" class="select-list-item__button">
      <img class="select-list-item__icon"
           src="assets/images/list-guest-logo.svg"><img>
    </button>
    <p class="select-list-item__button-text">
      {{ guestListText ? guestListText : '' }}
    </p>
  </div>
  <div class="select-list-item">
    <button (click)="emitSelectListTypeClicked(3)" class="select-list-item__button">
      <img class="select-list-item__icon"
           src="assets/images/list-custom-logo.svg"><img>
    </button>
    <p class="select-list-item__button-text">
      {{ customListText ? customListText : '' }}
    </p>
  </div>
</div>

