<div class="input-date">
  @if (label) {
    <label [for]="id" class="form-field-label">{{ label }}</label>
  }
  <input
    (blur)="onTouched()"
    (keypress)="isNumber($event)"
    (ngModelChange)="checkValue($event)"
    [id]="id"
    [ngClass]="{ 'date-field--has-value': hasValue, 'error': errorMessage }"
    [ngModel]="displayValue"
    class="input-date__field {{ variant }}"
    inputmode="decimal"
    maxlength="10"
    placeholder="dd.MM.yyyy"
    type="text"
  />
  @if (errorMessage) {
    <span class="input-date__error-message">
      {{ errorMessage }}
    </span>
  }
  @if (!errorMessage && formControl.errors && formControl.touched) {
    <span class="input-date__error-message">
      {{ formControl | translateError }}
    </span>
  }
</div>
