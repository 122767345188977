import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'firstError',
})
export class FirstErrorPipe implements PipeTransform {
  #translatePipe = inject(TranslatePipe);

  constructor(private readonly translationService: TranslateService) {}

  transform(errors: Record<string, string> | null | false | undefined): string {
    if (!errors) {
      return '';
    }

    const firstError = Object.keys(errors)[0];

    if (!firstError || firstError == '') {
      return '';
    }

    const key = `izzo.admin.forms.errors.${firstError}`;

    return this.#translatePipe.transform(key, errors[firstError]);
  }
}
