import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'izzo-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
})
export class InputCheckboxComponent {
  @Input()
  isChecked = false;

  @Input() label = '';
  @Output() valueChange = new EventEmitter<boolean>();
}
