<a (click)="emitOpenReservation()">
  <div class="reservation-card">
    <div class="reservation-card__invitees">
      <p>{{ countPersonInReservation }} </p>
      <svg-icon class="reservation-card__invitees-icon" color="#FFE248" key="group"></svg-icon>
    </div>
    <div class="reservation-card__text">
      <h2 class="reservation-card__text-title">{{ reservation?.name }}</h2>
    </div>
    <div class="reservation-card__status {{ reservation?.reservationStatus }}">
      {{ 'izzo.admin.orbit.reservation.' + reservation?.reservationStatus | translate }}
    </div>
  </div>
</a>

