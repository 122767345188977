<div class="form">
  <div class="form-wrapper">
    <div class="form-group">
      <div (keydown.enter)="emitUploadLoungeClicked()" class="lounge-detail">
        <izzo-input-text
          [errorMessage]="
            loungeFormGroup.controls.name
              | touchedErrors
              | firstError
              | translate
          "
          [formControl]="loungeFormGroup.controls.name"
          [label]="'izzo.admin.orbit.lounge.loungeName' | translate"
          [placeholder]="'izzo.admin.orbit.lounge.loungeName' | translate"
          id="loungeName"
          type="text"
          variant="dark"
        >
        </izzo-input-text>
        <izzo-lounge-type-dropdown
          [errorMessage]="
            loungeFormGroup.controls.loungeType
              | touchedErrors
              | firstError
              | translate
          "
          [formControl]="loungeFormGroup.controls.loungeType"
          [label]="'izzo.admin.orbit.lounge.type' | translate"
          [loungeTypes]="loungeTypes"
          [nothingChosenText]="
            'izzo.admin.uploadEvent.nothingChosen' | translate
          "
          class="input"
          id="lounge"
        >
        </izzo-lounge-type-dropdown>
        <izzo-input-text
          [errorMessage]="
            loungeFormGroup.controls.numberSeats
              | touchedErrors
              | firstError
              | translate
          "
          [formControl]="loungeFormGroup.controls.numberSeats"
          [label]="'izzo.admin.orbit.lounge.numberSeats' | translate"
          [placeholder]="'izzo.admin.orbit.lounge.numberSeats' | translate"
          id="numberSeats"
          type="number"
          variant="dark"
        >
        </izzo-input-text>
        <izzo-input-textarea
          [errorMessage]="
            loungeFormGroup.controls.comment
              | touchedErrors
              | firstError
              | translate
          "
          [formControl]="loungeFormGroup.controls.comment"
          [label]="'izzo.admin.orbit.lounge.comment' | translate"
          [placeholder]="'izzo.admin.orbit.lounge.comment' | translate"
          id="comment"
          type="text"
          variant="dark"
        >
        </izzo-input-textarea>
        <div class="form-group-buttons">
          <izzo-button
            (click)="emitUploadLoungeClicked()"
            [label]="'izzo.admin.orbit.lounge.save' | translate"
          ></izzo-button>
        </div>
      </div>
    </div>
  </div>
</div>
