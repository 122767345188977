import { Component, EventEmitter, Input, Output } from '@angular/core';
import { formatWithOptions } from 'date-fns/fp/formatWithOptions';
import { Event, SubscriptionData } from '../../../core/models/app.models';
import { de } from 'date-fns/locale';

@Component({
  selector: 'izzo-party-card',
  templateUrl: './party-card.component.html',
  styleUrls: ['./party-card.component.scss'],
})
export class PartyCardComponent {
  @Input() event!: Event | null;
  @Input() subscription!: SubscriptionData | null;
  @Input() roles!: string[] | null;
  @Input() readMode = false;
  @Output() editEventClicked = new EventEmitter<Event>();
  @Output() deleteEventClicked = new EventEmitter<Event>();
  @Output() openOrbitClicked = new EventEmitter<Event>();

  constructor() {}

  get roleSufficient() {
    return (
      (this.roles &&
        (this.roles.includes('GLOBAL_ADMIN') ||
          this.roles.includes('ASSISTANT'))) ||
      false
    );
  }

  get isEventPending() {
    return this.event?.eventStatus === 'pending';
  }

  get isEventDeclined() {
    return this.event?.eventStatus === 'declined';
  }

  formatDate(date: string | undefined) {
    if (date) {
      return formatWithOptions({ locale: de }, 'dd.MM.yyyy', new Date(date));
    }

    return '';
  }

  emitEditEvent() {
    this.editEventClicked.emit(this.event!);
  }

  emitDeleteEvent() {
    this.deleteEventClicked.emit(this.event!);
  }

  emitOpenOrbitEvent() {
    if (
      this.event?.eventStatus === 'online' ||
      this.event?.eventStatus === 'archived'
    ) {
      this.openOrbitClicked.emit(this.event!);
    }
  }
}
