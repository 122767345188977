<div class="list-invitees">
  <div class="back">
    <izzo-button
      (click)="emitBackClicked()"
      [isFullwidth]="true"
      [isSmall]="true"
      >{{ 'izzo.admin.list.backToOrbitOverview' | translate }}
    </izzo-button>
  </div>
  <div class="infos-main-inviteesRatio">
    <svg-icon color="#FFE248" key="group"></svg-icon>
    <div class="infos-main-inviteesRatio-text">
      {{ countInviteesInList }}/{{ list?.maxInvitees }}
    </div>
  </div>
  <div class="infos">
    <div class="infos-main">
      <h2 class="infos-main-title">{{ list?.name }}</h2>
      <p class="infos-main-email">{{ list?.emailPromoter }}</p>
      <p class="infos-main-price">
        {{ 'izzo.admin.list.priceText' | translate }}: {{ list?.price }} CHF
      </p>
    </div>
    <div class="infos-link">
      <div class="infos-link-copy">
        <izzo-input-text
          [errorMessage]="
            listLinkFormGroup.controls.link
              | touchedErrors
              | firstError
              | translate
          "
          [formControl]="listLinkFormGroup.controls.link"
          [placeholder]="'izzo.admin.list.link' | translate"
          [readonly]="true"
          class="infos-link-copy-input"
          variant="dark"
        >
        </izzo-input-text>
        <izzo-button
          (buttonClicked)="emitCopyLink()"
          [isFullwidth]="true"
          class="infos-link-copy-button"
          >{{ 'izzo.admin.list.copyText' | translate }}
        </izzo-button>
      </div>
      @if (copyTextVisible) {
        <p class="infos-link-copy-button-text">
          {{ 'izzo.admin.list.copyTextToClipboard' | translate }}
        </p>
      }
      @if (roleSufficient) {
        <izzo-button
          (buttonClicked)="emitCreateNewLink(list!)"
          [isFullwidth]="true"
          class="infos-link-button-newLink"
          variant="danger"
          >{{ 'izzo.admin.list.generateNewLink' | translate }}
        </izzo-button>
      }
    </div>
  </div>
  <div class="csv-wrapper">
    <div class="csv">
      <div class="csv-template">
        <a class="csv-template-link" (click)="emitGetCsvTemplateClicked()">
          {{ 'izzo.admin.list.templateCSV' | translate }}
        </a>
      </div>
      <div class="csv-importExport">
        <izzo-button
          (buttonClicked)="import.click()"
          [isFullwidth]="true"
          class="csv-importButton"
          variant="light-dark"
          >{{ 'izzo.admin.list.importCSV' | translate }}
        </izzo-button>
        <izzo-button
          (buttonClicked)="emitExportCsvClicked()"
          [isFullwidth]="true"
          class="csv-exportButton"
          variant="light-dark"
          >{{ 'izzo.admin.list.exportCSV' | translate }}
        </izzo-button>
      </div>
    </div>
  </div>
  <div class="addInviteeForms-wrapper">
    @if (roleSufficient) {
      <div
        (keydown.enter)="emitAddInviteeToListClicked()"
        class="addInviteeForms"
      >
        <izzo-input-text
          [formControl]="addInviteeToListFormGroup.controls.firstName"
          [errorMessage]="
            addInviteeToListFormGroup.controls.firstName
              | touchedErrors
              | firstError
              | translate
          "
          [placeholder]="'izzo.admin.list.firstName' | translate"
          variant="dark"
        ></izzo-input-text>
        <izzo-input-text
          [formControl]="addInviteeToListFormGroup.controls.name"
          [errorMessage]="
            addInviteeToListFormGroup.controls.name
              | touchedErrors
              | firstError
              | translate
          "
          [placeholder]="'izzo.admin.list.name' | translate"
          variant="dark"
        ></izzo-input-text>
        <izzo-input-text
          [formControl]="addInviteeToListFormGroup.controls.comment"
          [errorMessage]="
            addInviteeToListFormGroup.controls.comment
              | touchedErrors
              | firstError
              | translate
          "
          [placeholder]="'izzo.admin.list.comment' | translate"
          variant="dark"
        ></izzo-input-text>
        <div class="add-button">
          <izzo-button
            (click)="emitAddInviteeToListClicked()"
            [isFullwidth]="true"
            [label]="'izzo.admin.list.addInvitee' | translate"
            variant="gradient"
          ></izzo-button>
        </div>
      </div>
    }
  </div>
  <div class="csv-mobile-wrapper">
    <div class="csv-mobile">
      <div class="csv-mobile-template">
        <a class="csv-mobile-template-link" (click)="emitGetCsvTemplateClicked()">
          {{ 'izzo.admin.list.templateCSV' | translate }}
        </a>
      </div>
      <izzo-button
        (buttonClicked)="import.click()"
        [isFullwidth]="true"
        class="csv-mobile-importButton"
        variant="light-dark"
        >{{ 'izzo.admin.list.importCSV' | translate }}
      </izzo-button>
      <izzo-button
        (buttonClicked)="emitExportCsvClicked()"
        [isFullwidth]="true"
        class="csv-mobile-exportButton"
        variant="light-dark"
        >{{ 'izzo.admin.list.exportCSV' | translate }}
      </izzo-button>
    </div>
  </div>
  <div class="table-wrap">
    <table>
      <thead>
        <tr class="table-header">
          <th class="clickable-th">
            {{ 'izzo.admin.list.firstName' | translate }}
          </th>
          <th class="clickable-th">{{ 'izzo.admin.list.name' | translate }}</th>
          <th class="clickable-th">
            {{ 'izzo.admin.list.comment' | translate }}
          </th>
          <th class="clickable-th">
            {{ 'izzo.admin.list.checkIn' | translate }}
          </th>
          @if (roleSufficient) {
            <th class="clickable-th">
              {{ 'izzo.admin.list.options' | translate }}
            </th>
          }
        </tr>
      </thead>
      <tbody>
        @for (
          listInvitee of allInviteesInList
            | paginate: { itemsPerPage: 4, currentPage: page };
          track listInvitee
        ) {
          <tr>
            @if (listInvitee.isReadOnly === true) {
              <td class="hidden-small">{{ listInvitee.firstName }}</td>
              <td class="hidden-small">{{ listInvitee.name }}</td>
              <td class="hidden-small">{{ listInvitee.comment }}</td>
              <td class="hidden-small">
                <izzo-toggle-switch
                  (switchClicked)="emitCheckInOutInvitee(listInvitee)"
                  [disabled]="!roleSufficient"
                  [selected]="listInvitee.isCheckedIn! === true"
                >
                </izzo-toggle-switch>
              </td>
              @if (!isARecordInEdit && roleSufficient) {
                <td class="button-td">
                  <ng-container>
                    <izzo-button
                      (buttonClicked)="emitDeleteInviteeClicked(listInvitee)"
                      variant="danger"
                    >
                      <svg-icon key="delete"></svg-icon>
                    </izzo-button>
                    <izzo-button
                      (buttonClicked)="emitEditInviteeClicked(listInvitee)"
                      variant="light-dark"
                    >
                      <div class="list-icon">
                        <svg-icon key="edit"></svg-icon>
                      </div>
                    </izzo-button>
                  </ng-container>
                </td>
              }
            }
            @if (listInvitee.isReadOnly === false) {
              <ng-container class="tr-edit">
                <td>
                  <izzo-input-text
                    [formControl]="editInviteeFormGroup.controls.firstName"
                    [errorMessage]="
                      addInviteeToListFormGroup.controls.firstName
                        | touchedErrors
                        | firstError
                        | translate
                    "
                    [placeholder]="'izzo.admin.list.firstName' | translate"
                    variant="dark"
                    >{{ listInvitee.firstName }}
                  </izzo-input-text>
                </td>
                <td>
                  <izzo-input-text
                    [formControl]="editInviteeFormGroup.controls.name"
                    [errorMessage]="
                      editInviteeFormGroup.controls.name
                        | touchedErrors
                        | firstError
                        | translate
                    "
                    [placeholder]="'izzo.admin.list.name' | translate"
                    variant="dark"
                    >{{ listInvitee.name }}
                  </izzo-input-text>
                </td>
                <td>
                  <izzo-input-text
                    [formControl]="editInviteeFormGroup.controls.comment"
                    [errorMessage]="
                      editInviteeFormGroup.controls.comment
                        | touchedErrors
                        | firstError
                        | translate
                    "
                    [placeholder]="'izzo.admin.list.comment' | translate"
                    variant="dark"
                    >{{ listInvitee.comment }}
                  </izzo-input-text>
                </td>
                <td>
                  <izzo-toggle-switch
                    (switchClicked)="emitCheckInOutInvitee(listInvitee)"
                    [selected]="listInvitee.isCheckedIn! === true"
                  >
                  </izzo-toggle-switch>
                </td>
                <td class="button-td">
                  <izzo-button
                    (buttonClicked)="emitConfirmEditInviteeClicked(listInvitee)"
                    [label]="'izzo.admin.list.save' | translate"
                  >
                  </izzo-button>
                </td>
              </ng-container>
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
  <div class="pagination">
    <pagination-controls
    (pageChange)="page = $event"
      [autoHide]="true"
      [responsive]="true"
      class="pagination-control"
      nextLabel="{{ 'izzo.admin.pagination.nextLabel' | translate }}"
      previousLabel="{{ 'izzo.admin.pagination.previousLabel' | translate }}"
    ></pagination-controls>
  </div>
</div>

<input
  #import
  (change)="importFileChange()"
  accept="text/csv"
  style="display: none"
  type="file"
/>
