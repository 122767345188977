import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { List } from 'src/app/core/models/app.models';
import {
  ListStepOneFormGroup,
  ListStepTwoFormGroup,
} from '../../../core/models/form.models';

@Component({
  selector: 'izzo-create-edit-list-form',
  templateUrl: './create-edit-list-form.component.html',
  styleUrls: ['./create-edit-list-form.component.scss'],
})
export class CreateEditListFormComponent implements OnInit {
  @Input() listStepOneFormGroup!: FormGroup<ListStepOneFormGroup>;
  @Input() listStepTwoFormGroup!: FormGroup<ListStepTwoFormGroup>;
  @Input() currentStep!: number | null;
  @Input() listType!: number | null;
  @Input() price!: number | null;
  @Input() event!: Event | null;
  @Input() list!: List | null;
  @Input() isFreeEntry: boolean = false;
  @Input() editMode: boolean = false;
  @Output() nextFromStepOneClicked = new EventEmitter<void>();
  @Output() uploadClicked = new EventEmitter<void>();
  @Output() lastStepClicked = new EventEmitter<void>();
  @Output() selectListTypeClicked = new EventEmitter<number | null>();

  ngOnInit(): void {
    if (this.editMode) {
      if (this.listStepTwoFormGroup.controls.price.value) {
        this.isFreeEntry = false;
      }

      if (!this.listStepTwoFormGroup.controls.price.value) {
        this.listStepTwoFormGroup.controls.price.disable();
        this.isFreeEntry = true;
      }
    }
  }

  emitLastStepClicked() {
    this.lastStepClicked.emit();
  }

  emitUploadListClicked() {
    this.uploadClicked.emit();
  }

  emitSelectListTypeClicked(id: number | null) {
    this.selectListTypeClicked.emit(id);
  }

  toggleFreeEntry() {
    if (!this.isFreeEntry) {
      this.listStepTwoFormGroup.controls.price.disable();
      this.listStepTwoFormGroup.controls.price.setValue('0.00');
    } else {
      this.listStepTwoFormGroup.controls.price.enable();
    }

    this.isFreeEntry = !this.isFreeEntry;
  }
}
