import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Event, Lounge, Reservation } from 'src/app/core/models/app.models';
import { ReservationRequestFormGroup } from 'src/app/core/models/form.models';

export type ReservationRequestDialogResult = AssignResult | CancelResult;

type AssignResult = {
  type: 'assign';
};

type CancelResult = {
  type: 'cancel';
};

export interface ReservationRequestDialogData {
  reservation: Reservation;
  event: Event;
  lounges: Lounge[];
  form: FormGroup<ReservationRequestFormGroup>;
}

@Component({
  selector: 'izzo-reservation-request',
  templateUrl: './reservation-request.component.html',
  styleUrls: ['./reservation-request.component.scss'],
})
export class ReservationRequestComponent {
  @Input() reservation!: Reservation | null;
  @Input() event!: Event | null;
  @Input() lounges!: Lounge[] | null;
  @Input() reservationRequestFormGroup!: FormGroup<ReservationRequestFormGroup>;

  @Output() assignReservationClicked = new EventEmitter<Reservation>();
  @Output() cancelReservationClicked = new EventEmitter<Reservation>();

  constructor(
    @Inject(DIALOG_DATA)
    private readonly data: ReservationRequestDialogData,
    private readonly dialogRef: DialogRef<
      ReservationRequestDialogResult,
      unknown
    >,
  ) {
    this.reservation = data.reservation;
    this.event = data.event;
    this.lounges = data.lounges;
    this.reservationRequestFormGroup = data.form;
  }

  get availableLounges() {
    let filteredLounges = this.lounges!.filter(
      (lounge) =>
        lounge.reservation === null &&
        lounge.numberSeats >= this.reservation?.numberPersons!,
    );
    return filteredLounges;
  }

  emitAssignReservationClicked() {
    if (this.reservationRequestFormGroup.invalid) {
      this.reservationRequestFormGroup.markAllAsTouched();
      return;
    }
    this.assignReservationClicked.emit(this.reservation!);
    this.dialogRef.close({ type: 'assign' });
  }

  emitCancelReservationClicked() {
    this.cancelReservationClicked.emit(this.reservation!);
    this.dialogRef.close({ type: 'cancel' });
  }

  close() {
    this.dialogRef.close();
  }
}
