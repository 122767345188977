import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { List, ListInvitee } from '../../../core/models/app.models';
import {
  AddInviteeToListFormGroup,
  ListLinkFormGroup,
} from '../../../core/models/form.models';
import { importFileChange } from 'src/app/core/helpers/helpers-not-native';

@Component({
  selector: 'izzo-read-only-list-invitees',
  templateUrl: './readOnly-list-invitees.component.html',
  styleUrls: ['./readOnly-list-invitees.component.scss'],
})
export class ReadOnlyListInviteesComponent {
  @Input() addInviteeToListFormGroup!: FormGroup<AddInviteeToListFormGroup>;
  @Input() listLinkFormGroup!: FormGroup<ListLinkFormGroup>;
  @Input() addInviteeText: string = '';
  @Input() list!: List | null;
  @Input() listInvitees!: ListInvitee[] | null;
  @Output() addInviteeToListClicked = new EventEmitter<ListInvitee>();
  @Output() copyLinkClicked = new EventEmitter<void>();
  @Output() maxInviteesReached = new EventEmitter<void>();
  @Output() currentTimeOverValidTime = new EventEmitter<void>();
  @Output() importCsv = new EventEmitter<File>();
  @Output() exportCsvClick = new EventEmitter<void>();
  @Output() getCsvTemplateClick = new EventEmitter<void>();

  @ViewChild('import', { read: ElementRef })
  public readonly importInput!: ElementRef<HTMLInputElement>;

  page: number = 1;
  copyTextVisible: boolean = false;

  get allInviteesInList() {
    if (this.listInvitees != null) {
      return this.listInvitees;
    }
    return [];
  }

  get countInviteesInList() {
    if (this.listInvitees != null) {
      return this.listInvitees.length;
    }
    return [];
  }

  importFileChange(): void {
    importFileChange(
      this.importInput,
      this.listInvitees!,
      this.list!,
      this.maxInviteesReached,
      this.importCsv,
    );
  }

  emitGetCsvTemplateClicked() {
    this.getCsvTemplateClick.emit();
  }

  emitAddInviteeToListClicked() {
    if (this.listInvitees?.length! == this.list?.maxInvitees!) {
      this.maxInviteesReached.emit();
    } else {
      const dateTimeNow = new Date();
      const listValidDateTime = new Date(this.list?.validTillDate!);
      const timeArray = this.list?.validTillTime!.split(':');
      listValidDateTime.setHours(+timeArray![0]);
      listValidDateTime.setMinutes(+timeArray![1]);
      if (listValidDateTime < dateTimeNow) {
        this.currentTimeOverValidTime.emit();
      } else {
        this.addInviteeToListClicked.emit({
          ...this.addInviteeToListFormGroup.getRawValue(),
          isReadOnly: true,
          list: this.list!,
          timeStamp: new Date().toDateString(),
        });
      }
    }
  }

  emitExportCsvClicked() {
    this.exportCsvClick.emit();
  }

  emitCopyLink() {
    this.copyLinkClicked.emit();
    this.copyTextVisible = true;
    setTimeout(() => {
      this.copyTextVisible = false;
    }, 3000);
  }

  getPrice() {
    if (!this.list?.price) {
      return;
    }

    return `${this.list?.price} CHF`;
  }
}
