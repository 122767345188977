import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StepItem } from '../../../core/models/app.models';

@Component({
  selector: 'izzo-step-menu',
  templateUrl: './step-menu.component.html',
  styleUrls: ['./step-menu.component.scss'],
})
export class StepMenuComponent {
  @Input() public steps: StepItem[] = [];
  @Output() setStep = new EventEmitter<string>();

  emitSetStep(stepId: string) {
    this.setStep.emit(stepId);
  }

  getStatusIcon(step: StepItem) {
    switch (step.state) {
      case 'invalid': {
        return 'warning';
      }
      case 'valid': {
        return 'check-circle';
      }
      default: {
        return 'warning';
      }
    }
  }
}
