<button
  (click)="!disabled ? emitButtonClicked() : null"
  [ngClass]="{
    'full-width' : isFullwidth,
    'small': isSmall,
    '__disabled': disabled,
    'button__disabled': disabled
  }"
  class="button {{ variant }}"
>
  {{ label ? label : '' }}
  <ng-content></ng-content>
</button>
