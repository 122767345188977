import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'tabStatus',
  pure: false, // This is important to update the pipe when the form control changes
})
export class TabStatusPipe implements PipeTransform {
  transform(formGroup: FormGroup): string {
    if (formGroup.valid && formGroup.touched) {
      return 'valid';
    }

    if (formGroup.invalid && formGroup.touched) {
      return 'invalid';
    }

    return 'unvalidated';
  }
}
