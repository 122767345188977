import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubscriptionData } from '../../../../../core/models/app.models';

@Component({
  selector: 'izzo-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent {
  @Input() currentLanguage!: string | null;
  @Input() languages!: string[];
  @Input() subscription!: SubscriptionData | null;
  @Input() roles!: string[] | null;
  @Output() languageClicked = new EventEmitter<string>();

  emitLanguageClicked(language: string) {
    this.languageClicked.emit(language);
  }

  getSubscriptionActive() {
    if (!this.subscription) {
      return false;
    }

    return this.subscription.active;
  }
}
