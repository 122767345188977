import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { formatPrice, getLocationById } from 'src/app/core/helpers/helpers';
import {
  Customer,
  EventInformation,
  Genre,
  Label,
  Location,
  Product,
  StepItem,
  SubscriptionData,
  Tag,
} from '../../../core/models/app.models';
import {
  EventBoostersForm,
  EventGenreTagsForm,
  EventInformationForm,
  EventTicketingForm,
} from '../../../core/models/event-form.models';
import {
  EventStepFourFormGroup,
  EventStepOneFormGroup,
  EventStepThreeFormGroup,
  EventStepTwoFormGroup,
} from '../../../core/models/form.models';

@Component({
  selector: 'izzo-upload-edit-event-form',
  templateUrl: './upload-edit-event-form.component.html',
  styleUrls: ['./upload-edit-event-form.component.scss'],
})
export class UploadEditEventFormComponent implements OnChanges, OnInit {
  @Input() eventInformationFormGroup!: FormGroup<EventInformationForm>;
  @Input() eventTicketingFormGroup!: FormGroup<EventTicketingForm>;
  @Input() eventGenreTagsFormGroup!: FormGroup<EventGenreTagsForm>;
  @Input() eventBoostersFormGroup!: FormGroup<EventBoostersForm>;
  @Input() currentStep!: string | null;
  @Input() genres!: Genre[] | null;
  @Input() tags!: Tag[] | null;
  @Input() eventInformation!: EventInformation | null;
  @Input() products!: Product[] | null;
  @Input() steps!: StepItem[] | null;
  @Input() price!: number | null;
  @Input() locations!: Location[] | null;
  @Input() hasTicketLink: boolean = false;
  @Input() isFreeEvent: boolean = false;
  @Input() selectedGenres!: number[] | null;
  @Input() selectedTags!: number[] | null;
  @Input() originalImage!: string | null;
  @Input() currentCustomer!: Customer | null;
  @Input() internalLabels!: Label[] | null;
  @Input() subscription!: SubscriptionData | null;
  @Input() editMode = false;
  @Output() nextFromStepOneClicked = new EventEmitter<void>();
  @Output() nextFromStepTwoClicked = new EventEmitter<void>();
  @Output() nextFromStepThreeClicked = new EventEmitter<void>();
  @Output() nextFromStepFourClicked = new EventEmitter<void>();
  @Output() lastStepClicked = new EventEmitter<void>();
  @Output() uploadClicked = new EventEmitter<void>();
  @Output() selectionToggled = new EventEmitter<Product>();
  @Output() setStep = new EventEmitter<string>();
  @Output() toggleGenre = new EventEmitter<number>();
  @Output() toggleTag = new EventEmitter<number>();
  @Output() imageCroppedSuccessfully = new EventEmitter<string>();
  @Output() imageUploadedSuccessfully = new EventEmitter<string>();

  @Input() eventStepOneFormGroup!: FormGroup<EventStepOneFormGroup>;
  @Input() eventStepTwoFormGroup!: FormGroup<EventStepTwoFormGroup>;
  @Input() eventStepThreeFormGroup!: FormGroup<EventStepThreeFormGroup>;
  @Input() eventStepFourFormGroup!: FormGroup<EventStepFourFormGroup>;

  public searchGenresControl = new FormControl('');
  public searchTagsControl = new FormControl('');

  displayedGenres: Genre[] = [];
  showAllGenres: boolean = false;
  displayedTags: Tag[] = [];
  showAllTags: boolean = false;

  get isFlyerSelected() {
    if (!this.products) {
      return false;
    }

    return (
      this.products.filter(
        (product) => product.selected && product.key === 'flyer',
      ).length > 0
    );
  }

  ngOnInit(): void {
    if (this.editMode) {
      if (!this.eventTicketingFormGroup.controls.ticketLink.value) {
        this.eventTicketingFormGroup.controls.ticketLink.disable();
        this.eventTicketingFormGroup.controls.fyraTicketLink.disable();
        this.hasTicketLink = true;
      }

      if (!this.eventTicketingFormGroup.controls.fromPrice.value) {
        this.eventTicketingFormGroup.controls.fromPrice.disable();
        this.eventTicketingFormGroup.controls.fromPrice.setValue('0.00');
        this.isFreeEvent = true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['genres'] && changes['genres'].currentValue) {
      this.loadInitialGenreData();
    }

    if (changes['tags'] && changes['tags'].currentValue) {
      this.loadInitialTagData();
    }
  }

  loadInitialGenreData() {
    if (this.genres) {
      this.displayedGenres = this.showAllGenres
        ? [...this.genres]
        : this.genres.slice(0, 16);
    }
  }

  loadInitialTagData() {
    if (this.tags) {
      this.displayedTags = this.showAllTags
        ? [...this.tags]
        : this.tags.slice(0, 13);
    }
  }

  toggleShowAllGenres() {
    this.showAllGenres = !this.showAllGenres;

    if (this.showAllGenres) {
      this.displayedGenres = this.genres!;
    } else {
      this.displayedGenres = this.genres?.slice(0, 16) || [];
    }
  }

  toggleShowAllTags() {
    this.showAllTags = !this.showAllTags;

    if (this.showAllTags) {
      this.displayedTags = this.tags!;
    } else {
      this.displayedTags = this.tags?.slice(0, 13) || [];
    }
  }

  emitSetStep(stepId: string) {
    this.setStep.emit(stepId);
  }

  emitNextFromStepOneClicked() {
    this.nextFromStepOneClicked.emit();
  }

  emitLastStepClicked() {
    this.lastStepClicked.emit();
  }

  emitNextFromStepTwoClicked() {
    this.nextFromStepTwoClicked.emit();
  }

  emitNextFromStepThreeClicked() {
    this.nextFromStepThreeClicked.emit();
  }

  emitNextFromStepFourClicked() {
    this.nextFromStepFourClicked.emit();
  }

  emitUploadClicked() {
    this.uploadClicked.emit();
  }

  emitSelectionToggled(product: Product) {
    this.selectionToggled.emit(product);
  }

  emitToggleGenre(genreId: number) {
    this.toggleGenre.emit(genreId);
  }

  emitToggleTag(tagId: number) {
    this.toggleTag.emit(tagId);
  }

  getLocation(locationId: number) {
    if (!locationId) {
      return null;
    }

    const location = getLocationById(locationId, this.locations!);
    if (locationId === -1) {
      return this.eventInformationFormGroup?.controls.locationName.value
        ? this.eventInformationFormGroup?.controls.locationName.value +
            ', ' +
            this.eventInformationFormGroup?.controls.locationStreet.value +
            ', ' +
            this.eventInformationFormGroup?.controls.locationPlz.value +
            ' ' +
            this.eventInformationFormGroup?.controls.locationProvince.value
        : this.eventInformationFormGroup?.controls.locationStreet.value +
            ', ' +
            this.eventInformationFormGroup?.controls.locationPlz.value +
            ' ' +
            this.eventInformationFormGroup?.controls.locationProvince.value;
    }
    return (
      location?.name +
        ', ' +
        location?.street +
        ', ' +
        location?.place?.plz +
        ' ' +
        location?.place?.province || ''
    );
  }

  getFormattedPrice(price: number) {
    return formatPrice(price);
  }

  searchGenres() {
    if (!this.searchGenresControl.value) {
      // If the search query is empty, revert to initial state based on showAll
      this.loadInitialGenreData();
      return;
    }

    if (this.genres) {
      this.displayedGenres = this.genres.filter((genre) =>
        genre.genre
          .toLowerCase()
          .startsWith(this.searchGenresControl.value?.toLowerCase() ?? ''),
      );

      this.showAllGenres = this.displayedGenres.length > 16;
    }
  }

  searchTags() {
    if (!this.searchTagsControl.value) {
      // If the search query is empty, revert to initial state based on showAll
      this.loadInitialTagData();
      return;
    }

    if (this.tags) {
      this.displayedTags = this.tags.filter((tag) =>
        tag.name
          .toLowerCase()
          .startsWith(this.searchTagsControl.value?.toLowerCase() ?? '', 1),
      );

      this.showAllTags = this.displayedTags.length > 13;
    }
  }

  isGenreChecked(genre: Genre) {
    if (!this.selectedGenres) {
      return false;
    }

    return this.selectedGenres.includes(genre.id);
  }

  isTagChecked(tag: Tag) {
    if (!this.selectedTags) {
      return false;
    }

    return this.selectedTags.includes(tag.id);
  }

  emitImageCroppedSuccessfully(flyer: string) {
    this.imageCroppedSuccessfully.emit(flyer);
  }

  getSelectedGenresFull() {
    if (!this.genres) {
      return [];
    }

    if (!this.selectedGenres) {
      return [];
    }

    return this.genres.filter((genre) =>
      this.selectedGenres?.includes(genre.id),
    );
  }

  getSelectedTagsFull() {
    if (!this.tags) {
      return [];
    }

    if (!this.selectedTags) {
      return [];
    }

    return this.tags.filter((tag) => this.selectedTags?.includes(tag.id));
  }

  emitImageUploadedSuccessfully(imageUrl: string) {
    this.imageUploadedSuccessfully.emit(imageUrl);
  }

  anyProductSelected() {
    if (!this.products) {
      return false;
    }

    return this.products.filter((product) => product.selected).length > 0;
  }

  getSubscriptionActive() {
    if (!this.subscription) {
      return false;
    }

    return this.subscription.active;
  }

  toggleIsEventFree() {
    if (!this.isFreeEvent) {
      this.eventTicketingFormGroup.controls.fromPrice.disable();
      this.eventTicketingFormGroup.controls.fromPrice.setValue('0.00');
    } else {
      this.eventTicketingFormGroup.controls.fromPrice.enable();
      this.eventTicketingFormGroup.controls.fromPrice.setValue('');
    }

    this.isFreeEvent = !this.isFreeEvent;
  }

  toggleHasTicketLink() {
    if (!this.hasTicketLink) {
      this.eventTicketingFormGroup.controls.ticketLink.disable();
      this.eventTicketingFormGroup.controls.fyraTicketLink.disable();
      this.eventTicketingFormGroup.controls.ticketLink.setValue('');
      this.eventTicketingFormGroup.controls.fyraTicketLink.setValue('');
    } else {
      this.eventTicketingFormGroup.controls.ticketLink.enable();
      this.eventTicketingFormGroup.controls.fyraTicketLink.enable();
    }

    this.hasTicketLink = !this.hasTicketLink;
  }

  getLabelById(): Label | undefined {
    return this.internalLabels?.find(
      (label) => label.id === this.eventInformation?.internalLabel,
    );
  }
}
