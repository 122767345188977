<div class="row">
  @for (step of steps; track step; let i = $index) {
    <div class="step"
      (click)="stepClick.emit(i + 1)"
      [ngClass]="{ 'active': activeStep === i + 1 }"
      >
      <div class="label">
        <div class="number">{{i + 1}}</div>
        {{ step | translate }}
      </div>
      @if (steps.length !== i + 1) {
        <div class="seperator"></div>
      }
    </div>
  }
</div>