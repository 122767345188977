<div class="party-card">
  @if (!readMode) {
    <div class="party-card__menu">
      @if (roleSufficient) {
        <izzo-button (click)="emitEditEvent()" [isFullwidth]="true"
          [isSmall]="true">{{ 'izzo.admin.myEvents.edit' | translate }}
        </izzo-button>
      }
      @if (subscription && subscription.active) {
        <izzo-button (click)="emitOpenOrbitEvent()" [disabled]="isEventPending || isEventDeclined"
          [isFullwidth]="true"
          [isSmall]="true" variant="gradient">{{ 'izzo.admin.myEvents.orbit' | translate }}
        </izzo-button>
      }
      @if (roleSufficient) {
        <izzo-button (click)="emitDeleteEvent()" [isFullwidth]="true"
          [isSmall]="true"
          variant="danger">{{ 'izzo.admin.myEvents.delete' | translate }}
        </izzo-button>
      }
    </div>
  }
  <div class="party-card__text">
    <p class="party-card__text-title">{{ event?.name }}</p>
    <p class="party-card__text-date">{{ formatDate(event?.startDate) }}</p>
  </div>
  <div class="party-card__genre-wrap">
    @for (genre of event?.genres; track genre) {
      <div class="party-card__genre">
        {{ genre.genre }}
      </div>
    }
  </div>
  <div class="party-card__tag-wrap">
    @for (tag of event?.tags; track tag) {
      <div class="party-card__tag">
        {{ tag.name }}
      </div>
    }
  </div>
  <p class="party-card__location">
    {{ event?.location?.name || event?.locationName }}
  </p>
  <div class="party-card__status {{ event?.eventStatus }}">
    {{ 'izzo.admin.myEvents.' + event?.eventStatus | translate }}
  </div>
</div>
