<label class="checkbox-wrap">
  <div class="checkbox">
    @if (isChecked) {
      <svg-icon key="check" />
    }
    <input
      class="input"
      [(ngModel)]="isChecked"
      (ngModelChange)="valueChange.emit(isChecked)"
      type="checkbox"
      />
  </div>
  <label class="label">
    {{ label }}
  </label>
</label>
