import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Event,
  Genre,
  Location,
  SubscriptionData,
  Tag,
} from '../../../core/models/app.models';
import { format, isAfter, isBefore, isEqual, isValid } from 'date-fns';
import { FormGroup } from '@angular/forms';
import { EventFilterFormGroup } from '../../../core/models/form.models';

@Component({
  selector: 'izzo-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class EventListComponent {
  @Input() eventFilterFormGroup!: FormGroup<EventFilterFormGroup>;
  @Input() searchLabel: string = '';
  @Input() searchPlaceholder: string = '';
  @Input() filterResetLabel: string = '';
  @Input() dateFromLabel: string = '';
  @Input() dateToLabel: string = '';
  @Input() eventNameHeaderText: string = '';
  @Input() dateHeaderText: string = '';
  @Input() genreHeaderText: string = '';
  @Input() noEventsUploadedText: string = '';
  @Input() uploadNowText: string = '';
  @Input() events!: Event[] | null;
  @Input() usedGenres!: Genre[] | null;
  @Input() usedTags!: Tag[] | null;
  @Input() activeGenreFilters!: number[] | null;
  @Input() activeTagFilters!: number[] | null;
  @Input() minAges!: string[] | null;
  @Input() usedLocations!: Location[] | null;
  @Input() activeLocationFilters!: number[] | null;
  @Input() eventStatuses!: string[] | null;
  @Input() activeEventStatuses!: string[] | null;
  @Input() activeAgeRestrictions!: string[] | null;
  @Input() subscription!: SubscriptionData | null;
  @Input() roles!: string[] | null;

  @Output() editEventClicked = new EventEmitter<Event>();
  @Output() uploadEventClicked = new EventEmitter<void>();
  @Output() filterResetButtonClicked = new EventEmitter<void>();
  @Output() toggleGenreClicked = new EventEmitter<number>();
  @Output() toggleTagClicked = new EventEmitter<number>();
  @Output() toggleLocationClicked = new EventEmitter<number>();
  @Output() toggleStatusClicked = new EventEmitter<string>();
  @Output() resetGenreFiltersClicked = new EventEmitter<void>();
  @Output() resetTagFiltersClicked = new EventEmitter<void>();
  @Output() resetLocationFiltersClicked = new EventEmitter<void>();
  @Output() resetStatusFiltersClicked = new EventEmitter<void>();
  @Output() resetAgeFiltersClicked = new EventEmitter<void>();
  @Output() toggleAgeClicked = new EventEmitter<string>();
  @Output() deleteEventClicked = new EventEmitter<Event>();
  @Output() createListClicked = new EventEmitter<void>();
  @Output() openOrbitClicked = new EventEmitter<Event>();

  filterSectionActive: boolean = false;
  page: number = 1;

  get roleSufficient() {
    return (
      (this.roles &&
        (this.roles.includes('GLOBAL_ADMIN') ||
          this.roles.includes('ASSISTANT'))) ||
      false
    );
  }

  get visibleEvents() {
    if (this.events) {
      return this.events
        .filter((event) => {
          const searchString =
            this.eventFilterFormGroup.controls.filterContains.value;
          return (
            !searchString ||
            event.name.toLowerCase().includes(searchString.toLowerCase())
          );
        })
        .filter((event) => {
          const fromDate = this.eventFilterFormGroup.controls.dateFrom.value;
          if (fromDate && isValid(new Date(fromDate))) {
            return (
              isAfter(new Date(event.startDate), new Date(fromDate)) ||
              isEqual(new Date(event.startDate), new Date(fromDate))
            );
          }

          return event;
        })
        .filter((event) => {
          const toDate = this.eventFilterFormGroup.controls.dateTo.value;

          if (toDate && isValid(new Date(toDate))) {
            return (
              isBefore(new Date(event.startDate), new Date(toDate)) ||
              isEqual(new Date(event.startDate), new Date(toDate))
            );
          }

          return event;
        })
        .filter((event) => {
          if (this.activeGenreFilters && this.activeGenreFilters.length > 0) {
            return this.activeGenreFilters.some((id) =>
              event.genres.some((genre) => genre.id === id),
            );
          }

          return event;
        })
        .filter((event) => {
          if (this.activeTagFilters && this.activeTagFilters.length > 0) {
            return this.activeTagFilters.some((id) =>
              event.tags.some((tag) => tag.id === id),
            );
          }

          return event;
        })
        .filter((event) => {
          if (
            this.activeLocationFilters &&
            this.activeLocationFilters.length > 0
          ) {
            if (!event.location) {
              return false;
            }

            return this.activeLocationFilters.includes(event.location.id!);
          }

          return event;
        })
        .filter((event) => {
          if (this.activeEventStatuses && this.activeEventStatuses.length > 0) {
            return this.activeEventStatuses.includes(event.eventStatus);
          }

          return event;
        })
        .filter((event) => {
          if (
            this.activeAgeRestrictions &&
            this.activeAgeRestrictions.length > 0
          ) {
            return this.activeAgeRestrictions.some(
              (restriction) =>
                event.ageRestrictionMen.includes(restriction) ||
                event.ageRestrictionWomen.includes(restriction),
            );
          }

          return event;
        });
    }
    return [];
  }

  formatDate(date: string) {
    return format(new Date(date), 'dd.MM.yyyy');
  }

  resetPage() {
    this.page = 1;
  }

  emitEditEventClicked(event: Event) {
    this.editEventClicked.emit(event);
  }

  emitUploadEventClicked() {
    this.uploadEventClicked.emit();
  }

  emitOpenOrbitClicked(event: Event) {
    this.openOrbitClicked.emit(event);
  }

  emitToggleGenreClicked(genreId: number) {
    this.resetPage();
    this.toggleGenreClicked.emit(genreId);
  }

  emitToggleTagClicked(tagId: number) {
    this.resetPage();
    this.toggleTagClicked.emit(tagId);
  }

  emitToggleStatusClicked(status: string) {
    this.resetPage();
    this.toggleStatusClicked.emit(status);
  }

  emitToggleLocationClicked(locationId: number) {
    this.resetPage();
    this.toggleLocationClicked.emit(locationId);
  }

  filterButtonClicked() {
    this.resetPage();
    this.filterSectionActive = false;
  }

  getActiveGenreFilters() {
    return this.activeGenreFilters
      ?.map((activeGenreId) => {
        const genre = this.usedGenres?.find(
          (usedGenre) => usedGenre.id === activeGenreId,
        );
        return genre ? genre.genre : undefined;
      })
      .join(', ');
  }

  getActiveTagsFilters() {
    return this.activeTagFilters
      ?.map((activeTagId) => {
        const tag = this.usedTags?.find(
          (usedTag) => usedTag.id === activeTagId,
        );
        return tag ? tag.name : undefined;
      })
      .join(', ');
  }

  getActiveLocationFilters() {
    return this.activeLocationFilters
      ?.map((activeLocationId) => {
        const location = this.usedLocations?.find(
          (usedLocation) => usedLocation.id === activeLocationId,
        );
        return location ? location.name : undefined;
      })
      .join(', ');
  }

  getActiveStatusFilters() {
    return this.activeEventStatuses
      ?.map((activeEventStatus) => {
        return this.activeEventStatuses?.find(
          (usedEventStatus) => usedEventStatus === activeEventStatus,
        );
      })
      .join(', ');
  }

  getActiveAgeFilters() {
    return this.activeAgeRestrictions
      ?.map((activeAgeRestriction) => {
        return this.activeAgeRestrictions?.find(
          (usedActiveRestrictions) =>
            usedActiveRestrictions === activeAgeRestriction,
        );
      })
      .join(', ');
  }

  emitFilterResetButtonClicked() {
    this.filterResetButtonClicked.emit();
  }

  emitResetStatusFiltersClicked() {
    this.resetStatusFiltersClicked.emit();
  }

  emitResetGenreFiltersClicked() {
    this.resetGenreFiltersClicked.emit();
  }

  emitResetTagFiltersClicked() {
    this.resetTagFiltersClicked.emit();
  }

  emitResetAgeFiltersClicked() {
    this.resetAgeFiltersClicked.emit();
  }

  emitResetLocationFiltersClicked() {
    this.resetLocationFiltersClicked.emit();
  }

  emitToggleAgeClicked(minAge: string) {
    this.toggleAgeClicked.emit(minAge);
  }

  emitDeleteEventClicked(event: Event) {
    this.deleteEventClicked.emit(event);
  }
}
