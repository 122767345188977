import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Genre, Location, Tag } from '../../../core/models/app.models';

@Component({
  selector: 'izzo-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss'],
})
export class EventFilterComponent {
  @Input() usedGenres!: Genre[] | null;
  @Input() usedTags!: Tag[] | null;
  @Input() activeGenreFilters!: number[] | null;
  @Input() activeLocationFilters!: number[] | null;
  @Input() activeTagFilters!: number[] | null;
  @Input() minAges!: string[] | null;
  @Input() usedLocations!: Location[] | null;
  @Input() eventStatuses!: string[] | null;
  @Input() activeEventStatuses!: string[] | null;
  @Input() activeAgeRestrictions!: string[] | null;
  @Output() closeFilterClicked = new EventEmitter<void>();
  @Output() filterButtonClicked = new EventEmitter<void>();
  @Output() filterResetButtonClicked = new EventEmitter<void>();
  @Output() toggleGenreClicked = new EventEmitter<number>();
  @Output() toggleTagClicked = new EventEmitter<number>();
  @Output() toggleLocationClicked = new EventEmitter<number>();
  @Output() toggleStatusClicked = new EventEmitter<string>();
  @Output() toggleAgeClicked = new EventEmitter<string>();

  emitCloseFilterClicked() {
    this.closeFilterClicked.emit();
  }

  emitToggleGenreClicked(genre: number) {
    this.toggleGenreClicked.emit(genre);
  }

  isGenreSelected(id: number) {
    if (!this.activeGenreFilters) {
      return false;
    }

    return this.activeGenreFilters.includes(id);
  }

  emitToggleTagClicked(tag: number) {
    this.toggleTagClicked.emit(tag);
  }

  isTagSelected(id: number) {
    if (!this.activeTagFilters) {
      return false;
    }

    return this.activeTagFilters.includes(id);
  }

  emitToggleLocationClicked(locationId: number) {
    this.toggleLocationClicked.emit(locationId);
  }

  isLocationSelected(id: number) {
    if (!this.activeLocationFilters) {
      return false;
    }

    return this.activeLocationFilters.includes(id);
  }

  emitFilterButtonClicked() {
    this.filterButtonClicked.emit();
  }

  emitFilterResetButtonClicked() {
    this.filterResetButtonClicked.emit();
  }

  emitToggleStatusClicked(status: string) {
    this.toggleStatusClicked.emit(status);
  }

  isStatusSelected(eventStatus: string) {
    if (!this.activeEventStatuses) {
      return false;
    }

    return this.activeEventStatuses.includes(eventStatus);
  }

  isAgeSelected(minAge: string) {
    if (!this.activeAgeRestrictions) {
      return false;
    }

    return this.activeAgeRestrictions.includes(minAge);
  }

  emitToggleAgeClicked(minAge: string) {
    this.toggleAgeClicked.emit(minAge);
  }

  getTranslationKey(eventStatus: string) {
    return `izzo.admin.myEvents.${eventStatus}`;
  }
}
