import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'izzo-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent {
  @Input() selected: boolean = false;
  @Input() disabled: boolean = false;
  @Output() switchClicked = new EventEmitter<void>();

  emitSwitchClicked() {
    this.switchClicked.emit();
  }
}
