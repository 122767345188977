<div class="lounge-card">
  @if (roleSufficient) {
    <div class="lounge-card__menu">
      <izzo-button (click)="emitEditLounge()"
        [isFullwidth]="true"
        [isSmall]="true">{{ 'izzo.admin.orbit.lounge.editLounge' | translate }}
      </izzo-button>
      @if (lounge?.reservation && lounge?.reservation?.reservationStatus !== 'checked') {
        <izzo-button (click)="emitCheckInLounge()"
          [isFullwidth]="true"
          [isSmall]="true"
          variant="secondary">{{ 'izzo.admin.orbit.lounge.checkIn' | translate }}
        </izzo-button>
      }
      @if (canDelete) {
        <izzo-button (click)="emitDeleteLounge()" [isFullwidth]="true"
          [isSmall]="true"
          variant="danger">{{ 'izzo.admin.orbit.lounge.deleteLounge' | translate }}
        </izzo-button>
      }
    </div>
  }
  <div class="lounge-card__text">
    <h2 class="lounge-card__text-title">{{ lounge?.name }}</h2>
  </div>
    <div class="lounge-card__seats">
      @if (!!lounge?.reservation) {
      <p>{{ countPersonsInLounge }} / {{lounge?.numberSeats}} </p>
      }
      @else {
        <p>{{ countPersonsInLounge }}</p>
      }
      <svg-icon class="lounge-card__seats-icon" color="#FFE248" key="group"></svg-icon>
    </div>
  <div class="lounge-card__status {{ getLoungeStatus() }}">
    {{ 'izzo.admin.orbit.lounge.' + getLoungeStatus() | translate }}
  </div>
</div>
