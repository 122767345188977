import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { Region } from '../../../core/models/app.models';

@Component({
  selector: 'izzo-region-dropdown',
  templateUrl: './region-dropdown.component.html',
  styleUrl: './region-dropdown.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegionDropdownComponent),
      multi: true,
    },
  ],
})
export class RegionDropdownComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  @Input() label: string | undefined;
  @Input() id!: string;
  @Input() placeholder!: string;
  @Input() nothingChosenText!: string;
  @Input() newLocationText!: string;
  @Input() formControl!: UntypedFormControl;
  @Input() errorMessage!: string | null;
  @Input() regions!: Region[] | null;
  @Input() variant: 'light' | 'dark' = 'light';
  @Input() defaultValue!: Region | null;

  hasValue: boolean | undefined;

  onChange: any = () => {};

  ngOnInit(): void {
    this.checkValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('regions' in changes) {
      this.checkValue();
    }
  }

  checkValue(): void {
    this.hasValue = this.formControl.value !== null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: Region): void {
    if (val.id !== this.formControl.value.id) {
      this.formControl.setValue(val);
      this.onChange(val);
    }
  }
}
