import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { SubscriptionData } from '../../../core/models/app.models';

@Component({
  selector: 'izzo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  @Input() currentLanguage!: string | null;
  @Input() logoutLabel!: string;
  @Input() contactLabel!: string;
  @Input() dashboardLabel!: string;
  @Input() myEventsLabel!: string;
  @Input() clubLabel!: string;
  @Input() profileLabel!: string;
  @Input() roles!: string[] | null;
  @Input() uploadEventsLabel!: string;
  @Input() languages!: string[];
  @Input() subscription!: SubscriptionData | null;
  @Output() languageClicked = new EventEmitter<string>();
  @Output() logoutClicked = new EventEmitter<void>();
  @Output() uploadEventClicked = new EventEmitter<void>();
  mobileMenuActive: boolean = false;

  private routerSubscription: Subscription;

  constructor(private router: Router) {
    this.routerSubscription = router.events
      .pipe(filter((e: any): e is RouterEvent => e instanceof RouterEvent))
      .subscribe((e: RouterEvent) => {
        if (this.mobileMenuActive) {
          this.toggleMobileMenu();
        }
      });
  }

  get roleSufficient() {
    return (
      (this.roles &&
        (this.roles.includes('GLOBAL_ADMIN') ||
          this.roles.includes('ASSISTANT'))) ||
      false
    );
  }

  emitLanguageClicked(language: string) {
    this.languageClicked.emit(language);
  }

  emitLogoutClicked() {
    this.logoutClicked.emit();
  }

  emitUploadEventClicked() {
    this.uploadEventClicked.emit();
  }

  toggleMobileMenu() {
    this.mobileMenuActive = !this.mobileMenuActive;
    const html = document.getElementById('html');
    if (this.mobileMenuActive) {
      html?.classList.toggle('noscroll', true);
    } else {
      html?.classList.toggle('noscroll', false);
    }
  }

  ngOnDestroy() {
    // Unsubscribe from the router events when the component is destroyed
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  getSubscriptionActive() {
    if (!this.subscription) {
      return false;
    }

    return this.subscription.active;
  }
}
