<div class="allLounges">
  <div class="back">
    <izzo-button (click)="emitBackClicked()" [isFullwidth]="true" [isSmall]="true">{{ 'izzo.admin.list.backToOrbitOverview' | translate }}</izzo-button>
  </div>
  <h2>{{'izzo.admin.orbit.allLounges' | translate}}</h2>
  <div class="createLounge">
    <izzo-button (click)="emitCreateLoungeClicked()" [isFullwidth]="true" [isSmall]="true">{{ 'izzo.admin.orbit.lounge.createLoungeTitle' | translate }}</izzo-button>
  </div>
  <div class="search">
    <izzo-input-text
      [formControl]="filterLoungesFormGroup.controls.filterContains"
      [errorMessage]="filterLoungesFormGroup.controls.filterContains | touchedErrors | firstError | translate"
      variant="dark"
      [label]="'izzo.admin.shared.searchPlaceholder' | translate"
      [placeholder]="'izzo.admin.shared.searchPlaceholder' | translate">
    </izzo-input-text>
  </div>
  <div class="table-wrap">
    <table>
      @if (visibleLounges.length > 0) {
        <div>
          <thead>
            <tr class="table-header">
              <th class="clickable-th">{{ 'izzo.admin.orbit.lounge.loungeName' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.orbit.lounge.numberSeats' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.orbit.lounge.comment' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.orbit.lounge.type' | translate }}</th>
              <th class="clickable-th">{{ 'izzo.admin.orbit.lounge.status' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            @for (lounge of visibleLounges | paginate: { itemsPerPage: 15, currentPage: page }; track lounge) {
              <tr>
                <ng-container>
                  <td class="hidden-small">{{ lounge.name }}</td>
                  <td class="hidden-small">{{ lounge.numberSeats }}</td>
                  <td class="hidden-small">{{ lounge.comment }}</td>
                  <td class="hidden-small">{{ lounge.loungeType!.name }}</td>
                  @if (!lounge.reservation) {
                    <td class="hidden-small">{{ 'izzo.admin.orbit.lounge.' + lounge.loungeStatus! | translate }}</td>
                  }
                  @else{
                    <td class="hidden-small">{{ 'izzo.admin.orbit.lounge.' + lounge.reservation!.reservationStatus | translate }}</td>
                  }
                </ng-container>
              }
            </tbody>
          </div>
        }
      </table>
      <div class="pagination">
        <pagination-controls
          class="pagination-control"
          [autoHide]="true"
          [responsive]="true"
          nextLabel="{{'izzo.admin.pagination.nextLabel' | translate }}"
          previousLabel="{{'izzo.admin.pagination.previousLabel' | translate }}"
        ></pagination-controls>
      </div>
    </div>
