<div class="event-list">
  <div class="filters">
    <div class="filters-row">
      <izzo-input-text
        [errorMessage]="
          eventFilterFormGroup.controls.filterContains
            | touchedErrors
            | firstError
            | translate
        "
        [formControl]="eventFilterFormGroup.controls.filterContains"
        [label]="searchLabel"
        [placeholder]="searchPlaceholder"
        variant="dark"
      ></izzo-input-text>
      <div class="date-filters">
        <izzo-datepicker
          [errorMessage]="
            eventFilterFormGroup.controls.dateFrom
              | touchedErrors
              | firstError
              | translate
          "
          [formControl]="eventFilterFormGroup.controls.dateFrom"
          [label]="dateFromLabel"
          id="dateFrom"
          variant="dark"
        ></izzo-datepicker>
        <izzo-datepicker
          [errorMessage]="
            eventFilterFormGroup.controls.dateTo
              | touchedErrors
              | firstError
              | translate
          "
          [formControl]="eventFilterFormGroup.controls.dateTo"
          [label]="dateToLabel"
          id="dateTo"
          variant="dark"
        >
        </izzo-datepicker>
      </div>
    </div>
    <div class="filters-row-additional">
      @if (!filterSectionActive && events && events.length > 0) {
        <div class="filter-button filter-toggle">
          <izzo-button (click)="emitFilterResetButtonClicked()" [isFullwidth]="true"
            [label]="'izzo.admin.filters.button.reset' | translate"
            variant="secondary">
          </izzo-button>
          <izzo-button
            (click)="filterSectionActive = true"
            [isFullwidth]="true"
            [label]="'izzo.admin.filters.button.setFilter' | translate"
          ></izzo-button>
        </div>
      }
      @if (
        !filterSectionActive &&
        (getActiveGenreFilters() ||
          getActiveLocationFilters() ||
          getActiveStatusFilters() ||
          getActiveAgeFilters())
      ) {
        <div class="filters-active">
          @if (getActiveStatusFilters()) {
            <div class="filters-active-badge">
              <svg-icon
                (click)="emitResetStatusFiltersClicked()"
                class="filters-active-badge-delete"
                key="close"
              ></svg-icon>
              {{ getActiveStatusFilters() }}
            </div>
          }
          @if (getActiveGenreFilters()) {
            <div class="filters-active-badge">
              <svg-icon
                (click)="emitResetGenreFiltersClicked()"
                class="filters-active-badge-delete"
                key="close"
              ></svg-icon>
              {{ getActiveGenreFilters() }}
            </div>
          }
          @if (getActiveTagsFilters()) {
            <div class="filters-active-badge">
              <svg-icon
                (click)="emitResetTagFiltersClicked()"
                class="filters-active-badge-delete"
                key="close"
              ></svg-icon>
              {{ getActiveTagsFilters() }}
            </div>
          }
          @if (getActiveLocationFilters()) {
            <div class="filters-active-badge">
              <svg-icon
                (click)="emitResetLocationFiltersClicked()"
                class="filters-active-badge-delete"
                key="close"
              ></svg-icon>
              {{ getActiveLocationFilters() }}
            </div>
          }
          @if (getActiveAgeFilters()) {
            <div class="filters-active-badge">
              <svg-icon
                (click)="emitResetAgeFiltersClicked()"
                class="filters-active-badge-delete"
                key="close"
              ></svg-icon>
              {{ getActiveAgeFilters() }}
            </div>
          }
        </div>
      }
    </div>
    @if (filterSectionActive) {
      <div class="filter">
        <izzo-event-filter
          (closeFilterClicked)="filterSectionActive = false"
          (filterButtonClicked)="filterButtonClicked()"
          (filterResetButtonClicked)="emitFilterResetButtonClicked()"
          (toggleAgeClicked)="emitToggleAgeClicked($event)"
          (toggleGenreClicked)="emitToggleGenreClicked($event)"
          (toggleLocationClicked)="emitToggleLocationClicked($event)"
          (toggleStatusClicked)="emitToggleStatusClicked($event)"
          (toggleTagClicked)="emitToggleTagClicked($event)"
          [activeAgeRestrictions]="activeAgeRestrictions"
          [activeEventStatuses]="activeEventStatuses"
          [activeGenreFilters]="activeGenreFilters"
          [activeTagFilters]="activeTagFilters"
          [activeLocationFilters]="activeLocationFilters"
          [eventStatuses]="eventStatuses"
          [minAges]="minAges"
          [usedGenres]="usedGenres"
          [usedTags]="usedTags"
          [usedLocations]="usedLocations"
        ></izzo-event-filter>
      </div>
    }
  </div>
  @if (visibleEvents.length > 0) {
    <div class="card-wrap">
      @for (
        event of visibleEvents
          | paginate: { itemsPerPage: 15, currentPage: page };
        track event
      ) {
        <div class="card-wrap-item">
          <izzo-party-card
            (deleteEventClicked)="emitDeleteEventClicked($event)"
            (editEventClicked)="emitEditEventClicked($event)"
            (openOrbitClicked)="emitOpenOrbitClicked($event)"
            [event]="event"
            [roles]="roles"
            [subscription]="subscription"
          ></izzo-party-card>
        </div>
      }
    </div>
  }
  @if (visibleEvents.length < 1) {
    <izzo-eventbox-empty
      (uploadEventClicked)="emitUploadEventClicked()"
      [roles]="roles"
    ></izzo-eventbox-empty>
  }
  <div class="pagination">
    <pagination-controls
      (pageChange)="page = $event"
      [autoHide]="true"
      [responsive]="true"
      class="pagination-control"
      nextLabel="{{ 'izzo.admin.pagination.nextLabel' | translate }}"
      previousLabel="{{ 'izzo.admin.pagination.previousLabel' | translate }}"
    ></pagination-controls>
  </div>
</div>
